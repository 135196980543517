import { createSlice } from "@reduxjs/toolkit";

const initialState={
 phone_number:"",
 name:"",
 password:'',
 account_type:"",
}


const signupSlice=createSlice({
 name:"signup",
 initialState,
 reducers:{
    udpateField:(state,action)=>{
        const {values}=action.payload;
        Object.keys(values).forEach(key=>{
            state[key]=values[key]
        })
    },
    resetFields:(state)=>{
        Object.assign(state,initialState);
    }
 }
});


export const {udpateField,resetFields}=signupSlice.actions;
export default signupSlice.reducer;
