import * as Yup from "yup";

export const updateMealSchema = Yup.object({
    name: Yup.string()
        .min(2, "Name must be at least 2 characters")
        .max(50, "Name must be less than 50 characters")
        .required("Meal name is required"),

    price: Yup.number()
        .positive("Price must be a positive number")
        .required("Price is required"),

    ingredients: Yup.string()
        .min(5, "Ingredients must be at least 5 characters")
        .max(200, "Ingredients must be less than 200 characters")
        .required("Ingredients are required"),

    description: Yup.string()
        .min(10, "Description must be at least 10 characters")
        .max(300, "Description must be less than 300 characters")
        .required("Description is required"),

    main_image: Yup.mixed()
        .nullable() // ✅ Allows null values
        .test("fileType", "Only image files are allowed", (value) => {
            if (!value) return true; // ✅ Skip validation if image is not uploaded
            return ["image/jpeg", "image/png", "image/jpg"].includes(value.type);
        })
        .test("fileSize", "Image must be less than 40MB", (value) => {
            if (!value) return true; // ✅ Skip validation if image is not uploaded
            return value.size <= 40 * 1024 * 1024; // 20MB
        }),

    image1: Yup.mixed()
        .nullable() // ✅ Allows null values
        .test("fileType", "Only image files are allowed", (value) => {
            if (!value) return true;
            return ["image/jpeg", "image/png", "image/jpg"].includes(value.type);
        })
        .test("fileSize", "Image must be less than 40MB", (value) => {
            if (!value) return true;
            return value.size <= 40 * 1024 * 1024;
        }),

    image2: Yup.mixed()
        .nullable() // ✅ Allows null values
        .test("fileType", "Only image files are allowed", (value) => {
            if (!value) return true;
            return ["image/jpeg", "image/png", "image/jpg"].includes(value.type);
        })
        .test("fileSize", "Image must be less than 40MB", (value) => {
            if (!value) return true;
            return value.size <= 40 * 1024 * 1024;
        }),

    category_id: Yup.string()
        .required("Category is required"),

    is_non_veg: Yup.boolean()
        .required("Please specify if the meal is vegetarian or non-vegetarian"),
});
