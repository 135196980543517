import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>404</h1>
      <h2 style={styles.subheading}>Oops! Page Not Found</h2>
      <p style={styles.text}>
        The page you're looking for doesn't exist. It might have been removed, or the URL might be incorrect.
      </p>
      <Link to="/" style={styles.link}>Go Back to Home</Link>
    </div>
  );
};

const styles = {
  container: {
    textAlign: "center",
    padding: "50px",
    fontFamily: "Arial, sans-serif",
  },
  heading: {
    fontSize: "100px",
    color: "#ff4c4c",
    margin: "0",
  },
  subheading: {
    fontSize: "28px",
    color: "#333",
  },
  text: {
    fontSize: "18px",
    color: "#666",
    margin: "20px 0",
  },
  link: {
    textDecoration: "none",
    fontSize: "20px",
    color: "#ffbe33",
    fontWeight: "bold",
  },
};

export default NotFound;
