import React, { useEffect, useState } from 'react';
import NavBar from '../components/NavBar';
import { Button, ButtonGroup, createTheme, IconButton, ThemeProvider } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { useDeleteUserCartMutation, useFetchUserCartQuery } from '../reducers/cartSlice';
import { toast } from 'react-toastify';
import { validateImageUrl } from '../helper/validate';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const theme = createTheme({
  palette: {
    orange: {
      main: '#ffbe33',
    },
  },
  components: {
    MuiButtonGroup: {
      styleOverrides: {
        grouped: {
          borderRight: 'none',
          '&:not(:last-of-type)': {
            borderRight: 'none',
          },
        },
      },
    },
  },
});

const Cart = () => {
  const authData=useSelector((state)=>state.auth);
  const navigate = useNavigate();
  useEffect(()=>{
    if(!authData.is_authenticated){
      toast.warning("please login...");
      navigate("/");
      return;
    }
    if(authData.is_admin){
      toast.warning("you can't perform this action as admin...");
      navigate("/");
      return;
    }
  },[])
  const { data: cartItems, isLoading, refetch } = useFetchUserCartQuery();
  const [deleteCart] = useDeleteUserCartMutation();
  const [finalCalculation, setFinalCalculation] = useState({});
  // Initialize cart quantities
  useEffect(() => {
    if (cartItems) {
      const initialCalculation = {};
      cartItems.forEach((item) => {
        initialCalculation[item.meal.id] = {
          quantity: 1, // Default quantity is 1
          totalPrice: parseFloat(item.meal.price),
        };
      });
      setFinalCalculation(initialCalculation);
    }
  }, [cartItems]);

  // Remove meal from cart
  const handleRemoveFromCart = async (mealId) => {
    try {
      await deleteCart(mealId).unwrap();
      toast.success("Meal removed from the cart.");
      refetch();
    } catch (error) {
      toast.error(error?.data?.message || "Failed to remove meal from cart.");
    }
  };

  // Increase Quantity
  const increaseQuantity = (mealId, price) => {
    setFinalCalculation((prev) => ({
      ...prev,
      [mealId]: {
        quantity: prev[mealId]?.quantity + 1 || 1,
        totalPrice: (prev[mealId]?.quantity + 1) * price,
      },
    }));
  };

  // Decrease Quantity
  const decreaseQuantity = (mealId, price) => {
    setFinalCalculation((prev) => {
      if (prev[mealId]?.quantity > 1) {
        return {
          ...prev,
          [mealId]: {
            quantity: prev[mealId].quantity - 1,
            totalPrice: (prev[mealId].quantity - 1) * price,
          },
        };
      }
      return prev;
    });
  };

  // Calculate Total Amount
  const totalAmount = cartItems?.reduce(
    (total, item) => total + (finalCalculation[item.meal.id]?.totalPrice || parseFloat(item.meal.price)),
    0
  ).toFixed(2) || "0.00";

  // Handle Esewa Payment
  const handleEsewaPayment = () => {
    if (cartItems?.length === 0) {
      toast.warning("Your cart is empty!");
      return;
    }

    const transactionId = `TXN${new Date().getTime()}`; // Unique Transaction ID
    const esewaUrl = "https://rc-epay.esewa.com.np/api/epay/main/v2/form";
    const paymentData = {
      amt: totalAmount,
      psc: 0,
      pdc: 0,
      txAmt: 0,
      tAmt: totalAmount,
      pid: transactionId,
      scd: "epay_payment", // Esewa Test Merchant Code
      su: "http://localhost:8000/esewa-success/", // Success Redirect URL
      fu: "http://localhost:8000/esewa-failure/", // Failure Redirect URL
    };

    // Create & Submit Form Dynamically
    const form = document.createElement("form");
    form.method = "POST";
    form.action = esewaUrl;

    Object.keys(paymentData).forEach((key) => {
      const input = document.createElement("input");
      input.type = "hidden";
      input.name = key;
      input.value = paymentData[key];
      form.appendChild(input);
    });

    document.body.appendChild(form);
    form.submit();
  };

  if (isLoading) return <p>Loading your cart...</p>;

  return (
    <div className="container-fluid" style={{ padding: '0px' }}>
      <NavBar />
      <div className="container">
        <div className="row">
          {/* Cart Items Section */}
          <div className="col-lg-8">
            {cartItems?.length > 0 ? (
              cartItems.map((item) => (
                <div key={item.id} className="row cart_box my-2 border">
                  <div className="col-md-6 d-flex align-items-center">
                    <img
                      src={validateImageUrl(item.meal.main_image)}
                      alt={item.meal.name}
                      height={125}
                      width={125}
                      style={{ objectFit: 'cover', borderRadius: '8px' }}
                    />
                    <div className="mx-sm-2">
                      <h5>{item.meal.name}</h5>
                    </div>
                  </div>
                  <div className="col-md-5 d-flex flex-column">
                    <div className="d-flex justify-content-between">
                      <p>Each</p>
                      <p>Quantity</p>
                      <p>Total</p>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <p>Rs. {parseFloat(item.meal.price).toFixed(2)}</p>
                      <ThemeProvider theme={theme}>
                        <ButtonGroup variant="contained" size="small" color="orange" disableElevation>
                          <Button onClick={() => decreaseQuantity(item.meal.id, parseFloat(item.meal.price))}>-</Button>
                          <Button>{finalCalculation[item.meal.id]?.quantity || 1}</Button>
                          <Button onClick={() => increaseQuantity(item.meal.id, parseFloat(item.meal.price))}>+</Button>
                        </ButtonGroup>
                      </ThemeProvider>
                      <p>Rs. {finalCalculation[item.meal.id]?.totalPrice.toFixed(2) || parseFloat(item.meal.price).toFixed(2)}</p>
                    </div>
                  </div>
                  <div className="col-md-1 d-flex align-items-md-center justify-content-center">
                    <ThemeProvider theme={theme}>
                      <IconButton color="error" onClick={() => handleRemoveFromCart(item.meal.id)}>
                        <Delete />
                      </IconButton>
                    </ThemeProvider>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-center my-4">Your cart is empty.</p>
            )}
          </div>

          {/* Checkout Section */}
          <div className="col-lg-4">
            <div className="row">
              <div className="row d-flex justify-content-center">
                <h4 className="text-center">Cart Summary</h4>
                <p className="text-center">Total Items: {cartItems?.length || 0}</p>
                <p className="text-center">Total Price: Rs. {totalAmount}</p>
                <div className="w-auto">
                  <Button
                    className="mt-2"
                    variant="contained"
                    sx={{ backgroundColor: "#ffbe33", color: "white" }}
                    onClick={handleEsewaPayment}
                  >
                    Pay with Esewa
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cart;
