import { useDispatch } from "react-redux";
import { setAdmin, setAuthenticated } from "../reducers/authSlice";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";



const Logout = () => {
  const dispatch=useDispatch();
  const navigate=useNavigate();
  const handleLogout=()=>{
    document.cookie = "access=; path=/; max-age=0;";
    document.cookie = "refresh=; path=/; max-age=0;";
dispatch(setAuthenticated(false));
dispatch(setAdmin(false));
toast.success("Logout successfully...");
navigate("/");
  }
  useEffect(()=>{
   handleLogout();
  },[])
  return null;
}

export default Logout