import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    cartCount: 0, // Store the number of items in the cart
  },
  reducers: {
    setCartCount(state, action) {
      state.cartCount = action.payload;
    },
    incrementCartCount(state) {
      state.cartCount += 1;
    },
    decrementCartCount(state) {
      if (state.cartCount > 0) {
        state.cartCount -= 1;
      }
    },
    resetCart(state) {
      state.cartCount = 0;
    },
  },
});

export const { setCartCount, incrementCartCount, decrementCartCount, resetCart } = cartSlice.actions;
export default cartSlice.reducer;
