import { Button, IconButton, InputAdornment, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Visibility, VisibilityOff,Phone,Person} from '@mui/icons-material';
import { signUpSchema } from '../schemas/signup';
import { useFormik } from 'formik';
import { useUserOtpMutation } from '../authentication/auth';
import { useDispatch, useSelector } from 'react-redux';
import { udpateField } from '../reducers/signupSlice';
import { toast } from 'react-toastify';
import { handleError } from '../helper/error';

const Signup = () => {
   const dispatch=useDispatch();
    const navigate=useNavigate();
    const isAuth=useSelector((state)=>state.auth.is_authenticated);
   
    useEffect(()=>{
    // if(isAuth){
    //   navigate('/');
    //   toast.info("already logged in ..")
    // }
    },[isAuth,navigate])
    const bgImage=`${process.env.PUBLIC_URL}/images/hero-bg.png`;
    const credentials={name:'',phone_number:"", password: '',confirm_password:'',account_type:"customer"};
    const [userOtp,{isLoading,error}]=useUserOtpMutation();
    const {values,errors,touched,handleBlur,handleChange,handleSubmit}=useFormik({
        initialValues:credentials, 
        validationSchema:signUpSchema,
        onSubmit:async(values)=>{
              console.log("otp: ",values);
              try{
                 dispatch(udpateField({values}));
                 const {data,error}=await userOtp({phone_number:values.phone_number});
                 if(data){
                  toast.success(data.message);
                  navigate("/otp");
                 }else if(error){
                  console.log("error: ",error)
                  toast.error(handleError(error));
                 } 
                // navigate("/otp");
              
              }catch(error){
               toast.error(handleError(error));
              }
        }        
       
    })
   
   

    
    // const handleChange=(e)=>{
    //   const{name,value}=e.target;
    //   console.log("name: ",name,"value: ",value)
    //    setCredentials(prevState=>({
    //     ...prevState,[name]:value
    //    }))
    // }

    


    const[showPassword,setShowPassword]=useState(false);
    const[showConfirmPassword,setShowConfrimPassword]=useState(false);
    const handleClickShowPassword=()=>setShowPassword(!showPassword)
    const handleMouseDownPassword=(e)=>e.preventDefault();
    const handleClickShowConfrimPassword=()=>setShowConfrimPassword(!showConfirmPassword)
    const handleMouseDownConfrimPassword=(e)=>e.preventDefault();
  return (
    // <div className='container my-2'>
    //     <button className='btn btn-primary' onClick={()=>signIn("google")}>Login with Google</button>
    // </div>
    <div className="container-fluid" style={{backgroundImage:`url(${bgImage})`,backgroundSize:"cover"}}>
     <div className="container">
      <div className="row" style={{minHeight:"100vh"}}>
      <div className='col-md-6 col-12 '></div>
      <div className="col-md-6 col-12 d-flex flex-column justify-content-center">
      <form onSubmit={handleSubmit}>
        <div className='row' style={{
            backgroundColor:'rgba(0,0,0,0.5)',
        boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
      }}>
       
               <div className='row'>
                <h2 className='text-white text-center'>Signup</h2>
               </div>
                <div className='row d-flex justify-content-center'>
                  <div className='col-sm-6'>
                    <TextField
                    label="Full Name"
                    variant='filled'
                    fullWidth
                    margin='normal'
                    name='name'
                    type='text'
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.name && errors.name}
                    helperText={touched.name && errors.name ? errors.name: ''}
                    sx={{
                        backgroundColor:'white',
                        
                    }}
                    slotProps={
                        {
                         formHelperText:{
                           sx:{
                            color:'red',
                           }
                         }, 
                            input:{
                                endAdornment:(
                                    <InputAdornment position='end'>
                                        <Person></Person>
                                    </InputAdornment>
                                )
                            }
                        }
                    }
                    />
                  </div>
                </div>
               

               <div className='row  d-flex justify-content-center'>
               <div className='col-sm-6'>
               <TextField
              label="Contact Number"
              variant="filled"
              fullWidth
              margin="normal"
              name="phone_number"
              type="text"
              value={values.phone_number}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.phone_number && errors.phone_number}
              helperText={errors.phone_number && touched.phone_number ? errors.phone_number : ''}
              sx={{
                backgroundColor:"white"
              }}
              slotProps={{
                input:{
                    endAdornment:(
                        <InputAdornment position='end'>
                            <Phone></Phone>
                        </InputAdornment>
                    )
                }
            }}

            />
               </div>
</div>

<div className='row d-flex justify-content-center'>
<div className='col-sm-6'>
               <TextField
              label="Password"
              variant="filled"
              fullWidth
              margin="normal"
              name="password"
              type={showPassword ? 'text':'password'}
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.password && touched.password}
              helperText={errors.password && touched.password ? errors.password : ''}
              sx={{
                backgroundColor:"white"
              }}
              slotProps={{
                input:{
                endAdornment:(
                  <InputAdornment
                  position='end'>
                    <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    >{showPassword ? <VisibilityOff/>:<Visibility/>}</IconButton>
                  </InputAdornment>
                )
              }}}
            />
               </div>


</div>

<div className='row d-flex justify-content-center'>
<div className='col-sm-6'>
               <TextField
              label="Confirm Password"
              variant="filled"
              fullWidth
              margin="normal"
              name="confirm_password"
              type={showConfirmPassword? 'text':'password'}
              value={values.confirm_password}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.confirm_password && touched.confirm_password}
              helperText={errors.confirm_password && touched.confirm_password ? errors.confirm_password : ''}
              sx={{
                backgroundColor:"white"
              }}
              slotProps={
                {
                  input:{
                    endAdornment:(
                        <InputAdornment position='end'>
                          <IconButton onClick={handleClickShowConfrimPassword}
                          onMouseDown={handleMouseDownConfrimPassword} edge="end">
                         {showConfirmPassword ? <VisibilityOff/>:<Visibility/>}
                          </IconButton>
                        </InputAdornment>
                      )}}
                    
                    }
            />
               </div>


</div>

 <div className='row d-flex justify-content-center my-2'>
  <div className='col-sm-6'>
  <div className='row'>
  {/* <Button variant='contained' type='submit' color='error'>SIGNUP</Button> */}
    <Button className='mt-2' variant='contained' fullWidth disabled={isLoading} sx={{ backgroundColor: "#ffbe33", color: "white", "&.Mui-disabled": {
        backgroundColor: "#d3d3d3",
        "&:hover": {
        backgroundColor: "#e6a82e", // Darker shade on hover
      },
        color: "#a0a0a0", // Dimmed text
      }, }} type='submit'>
      {isLoading ? 'Loading..':'Signup'}</Button>
  </div>
  </div>
 </div>
{/* <div>
  <p className='text-white text-center' style={{fontSize:"13px"}}>OR</p>
</div>

 <div className='row d-flex justify-content-center mb-2'>
  <div className='col-sm-6'>
    <div className='row'>
    <Button variant='contained' onClick={()=>signIn("google")}> <img src={google} height={20} width={20} alt='google logo'></img>  <span className='mx-2'>Continue with Gooogle</span>  </Button>
    </div>
  </div>
 </div> */}

 <div className='row d-flex justify-content-center '>
    <div className='col-sm-6 d-flex justify-content-center'>
        <p className='text-center text-white' style={{fontSize:"13px"}}>Already have an account?</p>  <Link to='/login' style={{color:"#ffbe33",fontSize:"13px",marginLeft:"3px"}}> {" "}Login</Link>
    </div>
 </div>
  


        </div>
        </form>
      </div>



      </div>
     </div>

    </div>
  )
}

export default Signup