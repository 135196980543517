import { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import NavBar from "../components/NavBar";
import { useFetchLatestMealQuery, useFetchMealCategoryQuery, useFetchAllMealQuery } from "../reducers/mealSlice";
import { useNavigate } from "react-router-dom";
import { validateImageUrl } from '../helper/validate';
import { useFetchAboutQuery } from "../reducers/aboutSlice";
import Footer from "../components/Footer";
import { ShoppingCart } from "@mui/icons-material";
import {useCreateCartMutation} from '../reducers/cartSlice';
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { incrementCartCount } from "../reducers/cartCountSlice";
import Skeleton from "react-loading-skeleton";
import { CircularProgress } from "@mui/material";


export default function Home() {
  const authData=useSelector((state)=>state.auth);
  console.log("authData: ",authData);
  const bgImage = process.env.PUBLIC_URL + "/images/hero-bg.png";
  const restaurantImage = process.env.PUBLIC_URL + "/restaurant.jpg";
  const navigate=useNavigate();
  const dispatch=useDispatch();
  // ✅ Fetch Meals & Categories
  const { data: latestMeal,isLoading:loadingLatestMeal } = useFetchLatestMealQuery();
  const { data: categoryData,isLoading:loadingCategoryData } = useFetchMealCategoryQuery();
  const { data: allMeals,isLoading:loadingMeals } = useFetchAllMealQuery();
  const {data: aboutData,isLoading:loadingAbout}=useFetchAboutQuery();
  // ✅ State for Filtering
  const [selectedCategory, setSelectedCategory] = useState("*");

  // ✅ Isotope refs
  const gridRef = useRef(null);
  const isoRef = useRef(null);

  // ✅ Initialize Isotope AFTER meals are loaded
  useEffect(() => {
    if (allMeals?.results?.length > 0) {
      isoRef.current = new Isotope(gridRef.current, {
        itemSelector: ".meal-item",
        layoutMode: "masonry",
        percentPosition: true,
      });
    }
  }, [allMeals]); // Runs when all meals are fetched

  // ✅ Handle Category Filter
  const handleFilter = (categoryId) => {
    const filterValue = categoryId === "*" ? "*" : `.cat-${categoryId}`;
    const selectedClass=categoryId === "*" ? "*" : `cat-${categoryId}`;
    setSelectedCategory(selectedClass);
    isoRef.current?.arrange({ filter: filterValue });
  };

  
  const [createCart, { isLoading, error }] = useCreateCartMutation();
  
  
  const addToCart = async (mealId) => {
    if(authData.is_admin){
      toast.warning("you can't perform this action as admin...")
      return;
    }
    if(!authData.is_authenticated){
            toast.warning("Login first...");
            return;
    }

    try {
      const response = await createCart({ meal: mealId }).unwrap(); // Call the API
      dispatch(incrementCartCount());
      toast.success(response.message || "Meal added to cart!"); // Show success message

    } catch (err) {
      if (err?.data?.message) {
        toast.warning(err.data.message); // Show error message if provided
      } else {
        toast.error("Failed to add meal to cart. Please try again.");
      }
    }
  };
  

  return (
    <div className="container-fluid" style={{ padding: "0px" }}>
      <NavBar />

      {/* 🎡 Carousel Section */}
      {
        loadingLatestMeal ? <Skeleton count={22}></Skeleton> : <div id="carouselExampleDark" className="carousel slide">
        <div className="carousel-indicators">
          {latestMeal?.slice(0, 3).map((_, index) => (
            <button
              key={index}
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide-to={index}
              className={index === 0 ? "active" : ""}
              aria-label={`Slide ${index + 1}`}
            ></button>
          ))}
        </div>

        <div className="carousel-inner">
          {latestMeal?.slice(0, 3).map((meal, index) => (
            <div
              className={`carousel-item ${index === 0 ? "active" : ""}`}
              key={meal?.id || index}
              style={{
                backgroundImage: `url(${bgImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <img
                src={meal?.main_image && validateImageUrl(meal?.main_image)}
                className="d-block w-100"
                height={500}
                style={{ objectFit: "contain" }}
                alt={meal?.name || "Meal Image"}
              />
              <div className="carousel-caption d-none d-md-block text-white text-start">
                <h2>{meal?.name}</h2>
                <p className="w-25">{meal?.description?.slice(0, 200)}...</p>
                <button className="btncls" onClick={()=>addToCart(meal.id)}>Add to cart</button>
              </div>
            </div>
          ))}
        </div>

        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>

        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
      }

      {/* 🍽️ Food Section */}
      <section className="food_section layout_padding-bottom my-4">
        <div className="container">
          <div className="heading_container heading_center">
            <h2>Our Menu</h2>
          </div>

          {/* 📁 Category Filter */}
          <ul className="filters_menu">
            <li className={selectedCategory === "*" ? "active" : ""} onClick={() => handleFilter("*")}>
              All
            </li>
            {categoryData?.results?.map((category) => (
              <li
                key={category.id}
                className={selectedCategory === `cat-${category.id}` ? "active" : ""}
                onClick={() => handleFilter(category.id)}
              >
                {category.name}
              </li>
            ))}
          </ul>

          {/* 🍕 Meal Grid with Isotope */}
          {
            loadingMeals ?  <div className="row d-flex justify-content-center align-items-center">
              <CircularProgress
            sx={{
              color: "#ffbe33", // Custom color
            }}
            thickness={4} // Optional: Adjust thickness
            size={50} // Optional: Adjust size
          />
            </div> :<div className="filters-content">
            <div className="row grid" ref={gridRef}>
              {allMeals?.results?.map((meal) => (
                <div key={meal.id} className={`col-sm-6 col-lg-4 meal-item cat-${meal.category.id}`}>
                  <div className="box" style={{cursor:"pointer"}}>
                    <div className="img-box">
                      <img src={meal?.main_image && validateImageUrl(meal?.main_image)} alt={meal.name} onClick={()=>navigate(`/meal/${meal.id}`)} />
                    </div>
                    <div className="detail-box">
                      <h5>{meal.name}</h5>
                      <p>{meal?.description?.slice(0,120)}...</p>
                      <div className="options">
                        <h6>Rs {meal.price}</h6>
                        <button className="cart-button" 
  onClick={() => addToCart(meal.id)}>
  <ShoppingCart fontSize="small" />
</button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          }

          {/* <div className="btn-box">
            <a href="#">View More</a>
          </div> */}
        </div>
      </section>

      {/* 📖 About Section */}
      {/* for big screen */}
         <section
           className="about_section layout_padding d-none d-md-block"
           style={{
             backgroundImage: `url(${aboutData?.image ? validateImageUrl(aboutData?.image): restaurantImage})`,
             backgroundSize: "cover",
             backgroundPosition: "center",
             backgroundRepeat:"no-repeat"
           }}
         >
           <div className="container">
             <div className="row">
               <div className="detail-box">
                 <div className="heading_container">
                   <h2>
                     We Are <span>Pakiza Restaurant</span>
                   </h2>
                 </div>
                 {
                   loadingAbout ? <Skeleton count={10}></Skeleton> :  <p className="w-md-25 w-50 text-center" style={{ backgroundColor: "rgba(0, 0, 0, 0.7)", padding: "15px", borderRadius: "8px", lineHeight: "2" }}>
                   <span style={{ color: "#ffbe33", fontSize: "30px" }}>{aboutData?.description.slice(0,1)}</span>{aboutData?.description.slice(1)}
                 </p>
                 }
               </div>
             </div>
           </div>
         </section>
   


{/* for small screen */}
         <section
           className="about_section layout_padding d-md-none"
           style={{
             backgroundImage: `url(${aboutData?.image ? validateImageUrl(aboutData?.image): restaurantImage})`,
             backgroundSize: "contain",
             backgroundPosition: "center",
             backgroundRepeat:"no-repeat"
           }}
         >
           <div className="container">
             <div className="row">
               <div className="detail-box">
                 <div className="heading_container">
                   <h2>
                     We Are <span>Pakiza Restaurant</span>
                   </h2>
                 </div>
                 {
                   loadingAbout ? <Skeleton count={10}></Skeleton> :  <p className="w-md-25  text-center" style={{ backgroundColor: "rgba(0, 0, 0, 0.7)", padding: "15px", borderRadius: "8px", lineHeight: "2",fontSize:"10px" }}>
                   <span style={{ color: "#ffbe33", fontSize: "16px" }}>{aboutData?.description.slice(0,1)}</span>{aboutData?.description.slice(1,150)}....
                 </p>
                 }
               </div>
             </div>
           </div>
         </section>



      {/* Loaction Section */}
      <section className="book_section layout_padding">
    <div className="container">
      <div className="heading_container">
        <h2>
          Find Us
        </h2>
      </div>
      <div className="row">
         <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3357.561153463038!2d85.3149384752538!3d27.706838976182823!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb1900956ecd5b%3A0x777e50e6b30b0619!2sPAKIZA%20RESTAURANT!5e1!3m2!1sen!2snp!4v1740804373721!5m2!1sen!2snp" width="600" height="450" allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="restaurant location"></iframe>
         </div>
    </div>
  </section>




      {/* 📢 Footer */}
      <Footer></Footer>
    </div>
  );
}
