import { createSlice } from "@reduxjs/toolkit";


const authSlice=createSlice({
    name:'auth',
    initialState:{
        is_authenticated:false,
        is_admin:false
    },
    reducers:{
        setAuthenticated(state,action){
            state.is_authenticated=action.payload;
        },
        setAdmin(state,action){
            state.is_admin=action.payload;
        }
    }
});
export const {setAuthenticated,setAdmin}=authSlice.actions;
export default authSlice.reducer;





