import './App.css';
import { Provider } from 'react-redux';
import { BrowserRouter as Router,Routes,Route } from 'react-router-dom';
import store from './store/store';
import { ToastContainer } from 'react-toastify';
import Home from './pages/Home';
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Menu from './pages/Menu';
import Search from './pages/Search';
import Cart from './pages/Cart';
import Meal from './pages/Meal';
import Signup from './pages/Signup';
import Login from './pages/Login';
import Otp from './pages/Otp';
// import BookTable from './pages/BookTable';
import AuthenticateUser from './components/AuthenticateUser';
import About from './pages/About';
import ForgotPassword from './pages/ForgetPassword';
import Profile from './pages/Profile';
import ChangePassword from './pages/ChangePassword';
import Logout from './pages/Logout';
import NotFound from './pages/NotFound';
import FeatureUnavailable from './pages/FeatureUnavailable';
import AddMeal from './admin/AddMeal';
import Meals from './admin/Meals';
import UpdateMeal from './admin/UpdateMeal';
import UserList from './admin/UserList';
import AddUserAdmin from './admin/AddAdmin';
import CreateAbout from './admin/About';
import MealList from './admin/MealList';
import Category from './admin/Category';
import AppRoutes from './AppRoutes';




function App() {
  return (
   <Provider store={store}>
    <AuthenticateUser></AuthenticateUser>
    <Router>
      <ToastContainer/>
      <AppRoutes></AppRoutes>
    </Router>
   
   </Provider>
  );
}

export default App;
