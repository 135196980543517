import React, { useEffect, useState } from "react";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import { Visibility, VisibilityOff, Phone } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useUserLoginMutation } from "../authentication/auth";
import { loginSchema } from "../schemas/login";
import { toast } from "react-toastify";
import { handleError } from "../helper/error";

const Login = () => {
  const navigate=useNavigate();
    const bgImage=`${process.env.PUBLIC_URL}/images/hero-bg.png`;
  const isAuth=useSelector((state)=>state.auth.is_authenticated);
  console.log("isAuth: ",isAuth);
  // useEffect(()=>{
  //   if(isAuth){
  //     console.log("inside isAuth if");
  //     navigate("/");
  //     toast.info("Already logged in");
  //   }
  // },[isAuth,navigate])

  const credentials = { phone: "", password: "" };
  const[userLogin,{isLoading,error}]=useUserLoginMutation();
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: credentials,
    validationSchema: loginSchema, 
    onSubmit: async(values) => {
    const{data,error}=await userLogin({phone_number:values.phone,password:values.password});
    if(data){
      toast.success(data.message);
      navigate("/");
    }else if(error){
      toast.error(handleError(error));
    }
    },
  });

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (e) => e.preventDefault();

  return (
    <div className="container-fluid" style={{ backgroundImage: `url(${bgImage})`, backgroundSize: "cover" }}>
      <div className="container">
        <div className="row" style={{ minHeight: "100vh" }}>
          <div className="col-md-6 col-12"></div>
          <div className="col-md-6 col-12 d-flex flex-column justify-content-center">
            <form onSubmit={handleSubmit}>
              <div
                className="row"
                style={{
                  backgroundColor: "rgba(0,0,0,0.5)",
                  boxShadow: "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                }}
              >
                <div className="row">
                  <h2 className="text-white text-center">Login</h2>
                </div>
                <div className="row d-flex justify-content-center">
                  <div className="col-sm-6">
                    <TextField
                      label="Phone Number"
                      variant="filled"
                      fullWidth
                      margin="normal"
                      name="phone"
                      type="tel"
                      value={values.phone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.phone && !!errors.phone}
                      helperText={touched.phone && errors.phone ? errors.phone : ""}
                      sx={{ backgroundColor: "white" }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Phone />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>

                <div className="row d-flex justify-content-center">
                  <div className="col-sm-6">
                    <TextField
                      label="Password"
                      variant="filled"
                      fullWidth
                      margin="normal"
                      name="password"
                      type={showPassword ? "text" : "password"}
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.password && !!errors.password}
                      helperText={touched.password && errors.password ? errors.password : ""}
                      sx={{ backgroundColor: "white" }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
                <div className="row justify-content-end">
                <div className="col-sm-6">
                <div className="w-auto">
                <Button 
    variant="text" 
    sx={{ 
        textTransform: "none",  // Prevents uppercase text
        color: "#007bff",       // Bootstrap primary color
        fontSize: "12px",
        fontWeight: "bold",
        textDecoration: "underline", // Adds underline effect
        "&:hover": {
            color: "#0056b3",  // Darker blue on hover
            textDecoration: "underline"
        }
    }}
    onClick={()=>navigate('/forget-password')} // Function to handle the event
>
    Forgot Password?
</Button>
                </div>
                </div>
                </div>

                <div className="row d-flex justify-content-center my-2">
                  <div className="col-sm-6 d-flex justify-content-center">
                    {/* <Button variant="contained" type="submit" color="error" fullWidth>
                      LOGIN

                    </Button> */}
                     
                     <Button className='mt-2' variant='contained' fullWidth disabled={isLoading} sx={{ backgroundColor: "#ffbe33", color: "white", "&.Mui-disabled": {
      backgroundColor: "#d3d3d3",
      "&:hover": {
      backgroundColor: "#e6a82e", // Darker shade on hover
    },
      color: "#a0a0a0", // Dimmed text
    }, }} type='submit'>
                                                                                        {isLoading ? 'Loading..' : 'Login'}
                                                                                    </Button>

                  </div>
                </div>

                {/* <div>
                  <p className="text-white text-center" style={{ fontSize: "13px" }}>
                    OR
                  </p>
                </div>

                <div className="row d-flex justify-content-center mb-2">
                  <div className="col-sm-6">
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={() => signIn("google")}
                    >
                      <img src={google} height={20} width={20} alt="google logo" />
                      <span className="mx-2">Continue with Google</span>
                    </Button>
                  </div>
                </div> */}



                <div className="row d-flex justify-content-center my-2">
                  <div className="col-sm-6 d-flex justify-content-center">
                    <p className="text-center text-white" style={{ fontSize: "13px" }}>
                      Don't have an account?
                    </p>
                    <Link to="/signup" style={{ color: "#ffbe33", fontSize: "13px", marginLeft: "3px" }}>
                      {" "}
                      Sign Up
                    </Link>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
