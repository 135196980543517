import * as Yup from "yup";

export const mealSchema = Yup.object({
    name: Yup.string()
        .min(2, "Name must be at least 2 characters")
        .max(50, "Name must be less than 50 characters")
        .required("Meal name is required"),

    price: Yup.number()
        .positive("Price must be a positive number")
        .required("Price is required"),

    half_plate_price: Yup.number()
        .positive("Price must be a positive number")
        .nullable()
        .when("meal_type", {
            is: "Plate",
            then: (schema) => schema.required("Half Plate Price is required for Plate meals"),
        }),

    ingredients: Yup.string()
        .min(5, "Ingredients must be at least 5 characters")
        .max(200, "Ingredients must be less than 200 characters")
        .required("Ingredients are required"),

    description: Yup.string()
        .min(100, "Description must be at least 200 characters")
        .max(300, "Description must be less than 300 characters")
        .required("Description is required"),

    main_image: Yup.mixed()
        .required("Main image is required")
        .test("fileType", "Only image files are allowed", (value) => {
            return value && ["image/jpeg", "image/png", "image/jpg"].includes(value.type);
        })
        .test("fileSize", "Image must be less than 40MB", (value) => {
            return value && value.size <= 40 * 1024 * 1024;
        }),

    image1: Yup.mixed()
        .nullable()
        .test("fileType", "Only image files are allowed", (value) => {
            return !value || ["image/jpeg", "image/png", "image/jpg"].includes(value.type);
        })
        .test("fileSize", "Image must be less than 40MB", (value) => {
            return !value || value.size <= 40 * 1024 * 1024;
        }),

    image2: Yup.mixed()
        .nullable()
        .test("fileType", "Only image files are allowed", (value) => {
            return !value || ["image/jpeg", "image/png", "image/jpg"].includes(value.type);
        })
        .test("fileSize", "Image must be less than 40MB", (value) => {
            return !value || value.size <= 40 * 1024 * 1024;
        }),

    category_id: Yup.string()
        .required("Category is required"),

    is_non_veg: Yup.boolean()
        .required("Please specify if the meal is vegetarian or non-vegetarian"),

    meal_type: Yup.string()
        .oneOf(["Plate", "Piece"], "Meal type must be Plate or Piece")
        .required("Meal type is required"),
});
