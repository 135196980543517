import React from 'react'
import { useFetchAboutQuery } from '../reducers/aboutSlice';
import Footer from '../components/Footer';
import { validateImageUrl } from '../helper/validate';
import NavBar from '../components/NavBar';
import Skeleton from 'react-loading-skeleton';
const About = () => {
  const bgImage = process.env.PUBLIC_URL + "/images/hero-bg.png";
  const restaurantImage = process.env.PUBLIC_URL + "/restaurant.jpg";
   const {data: aboutData,isLoading:loadingAbout}=useFetchAboutQuery();
  return (
    <div className="container-fluid" style={{ padding: "0px" }}>
      <NavBar /> 
      

  {/* for big screen */}
         <section
           className="about_section layout_padding d-none d-md-block"
           style={{
             backgroundImage: `url(${aboutData?.image ? validateImageUrl(aboutData?.image): restaurantImage})`,
             backgroundSize: "cover",
             backgroundPosition: "center",
             backgroundRepeat:"no-repeat"
           }}
         >
           <div className="container">
             <div className="row">
               <div className="detail-box">
                 <div className="heading_container">
                   <h2>
                     We Are <span>Pakiza Restaurant</span>
                   </h2>
                 </div>
                 {
                   loadingAbout ? <Skeleton count={10}></Skeleton> :  <p className="w-md-25 w-50 text-center" style={{ backgroundColor: "rgba(0, 0, 0, 0.7)", padding: "15px", borderRadius: "8px", lineHeight: "2" }}>
                   <span style={{ color: "#ffbe33", fontSize: "30px" }}>{aboutData?.description.slice(0,1)}</span>{aboutData?.description.slice(1)}
                 </p>
                 }
               </div>
             </div>
           </div>
         </section>
   
   
   
        {/* for small screen */}
         <section
           className="about_section layout_padding d-md-none"
           style={{
             backgroundImage: `url(${aboutData?.image ? validateImageUrl(aboutData?.image): restaurantImage})`,
             backgroundSize: "contain",
             backgroundPosition: "center",
             backgroundRepeat:"no-repeat"
           }}
         >
           <div className="container">
             <div className="row">
               <div className="detail-box">
                 <div className="heading_container">
                   <h2>
                     We Are <span>Pakiza Restaurant</span>
                   </h2>
                 </div>
                 {
                   loadingAbout ? <Skeleton count={10}></Skeleton> :  <p className="w-md-25  text-center" style={{ backgroundColor: "rgba(0, 0, 0, 0.7)", padding: "15px", borderRadius: "8px", lineHeight: "2",fontSize:"10px" }}>
                   <span style={{ color: "#ffbe33", fontSize: "16px" }}>{aboutData?.description.slice(0,1)}</span>{aboutData?.description.slice(1,150)}....
                 </p>
                 }
               </div>
             </div>
           </div>
         </section>



      {/* Loaction Section */}
      <section className="book_section layout_padding">
    <div className="container">
      <div className="heading_container">
        <h2>
          Find Us
        </h2>
      </div>
      <div className="row">
       
         <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3357.561153463038!2d85.3149384752538!3d27.706838976182823!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb1900956ecd5b%3A0x777e50e6b30b0619!2sPAKIZA%20RESTAURANT!5e1!3m2!1sen!2snp!4v1740804373721!5m2!1sen!2snp" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
         </div>
    </div>
  </section>




      {/* 📢 Footer */}
      <Footer></Footer>
     
    </div>
  )
}

export default About
