import React, { useEffect, useRef, useState } from "react";
import NavBar from "../components/NavBar";
import {
  Avatar,
  IconButton,
  Paper,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Card,
  CardContent,
  Typography,
  Divider,
} from "@mui/material";
import { Edit, Logout, ShoppingBag,Phone, Favorite, Delete,PasswordOutlined, } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useUserProfileQuery, useUpdateUserProfileMutation } from "../reducers/userSlice";
import { toast } from "react-toastify";
import { useFetchUserFavoriteMealQuery, useRemoveFavoriteMealMutation } from "../reducers/mealSlice";
import { validateImageUrl } from '../helper/validate';
import { useSelector } from "react-redux";

const Profile = () => {
  const authData=useSelector((state)=>state.auth);
  useEffect(()=>{
    if(!authData.is_authenticated){
      toast.warning("please login first..");
      navigate("/");
    }
  },[])
  const navigate = useNavigate();
  const { data: userData, isLoading, error, refetch } = useUserProfileQuery();
  const { data: favoriteMeals, refetch: refetchFavorites } = useFetchUserFavoriteMealQuery();
  const [removeFavoriteMeal] = useRemoveFavoriteMealMutation();
  const [updateUserProfile] = useUpdateUserProfileMutation();
  const profilePicRef = useRef();
  const [profilePicture, setProfilePicture] = useState("");
  const [userDetails, setUserDetails] = useState({
    name: "",
    phone_number: "",
    profile_picture:""
  });

  // Fetch user data
  useEffect(() => {
    if (userData) {
      setUserDetails({
        name: userData?.name || "",
        phone_number: userData?.phone_number || "",
      });
      setProfilePicture(validateImageUrl(userData?.profile_picture));
    }
  }, [userData]);

  // Handle form input changes
  const handleInputChange = (e) => {
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
  };

  // Handle profile picture selection
  const handleSelectProfilePicture = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setProfilePicture(reader.result);
        setUserDetails((prevDetails) => ({
          ...prevDetails,
          profile_picture: file, // Set the file in userDetails
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  // Handle profile update
  const handleUpdateProfile = async () => {
    try {
        const formData = new FormData();
        Object.keys(userDetails).forEach(key => {
            formData.append(key, userDetails[key]);
        });

        const response = await updateUserProfile(formData).unwrap();
        toast.success(response.message || "Profile updated successfully!");
        refetch();
    } catch (err) {
        toast.error("Failed to update profile.");
    }
};

  // Handle removing a favorite meal
  const handleRemoveFavorite = async (mealId) => {
    try {
      await removeFavoriteMeal(mealId).unwrap();
      toast.success("Removed from favorites!");
      refetchFavorites();
    } catch (error) {
      toast.error("Error removing favorite meal.");
    }
  };

  // Handle logout
  const handleLogout = () => {
    toast.info("Logging out...");
    navigate("/logout");
  };

  if (isLoading) return <p>Loading profile...</p>;
  if (error) return <p>Error loading profile.</p>;

  return (
    <div className="container-fluid" style={{ padding: "0px" }}>
      <NavBar />
      <div className="row mt-3">
        {/* Sidebar Navigation */}
        <div className="col-lg-3 col-md-4 d-none d-md-block">
          <Paper sx={{ width: 280, maxWidth: "100%" }}>
            <MenuList>
              <MenuItem>
                <ListItemIcon>
                   <PasswordOutlined></PasswordOutlined>
                </ListItemIcon>
                <ListItemText>Change Password</ListItemText>
              </MenuItem>
              <MenuItem data-bs-toggle="modal" data-bs-target="#editProfileModal">
                <ListItemIcon>
                  <Edit />
                </ListItemIcon>
                <ListItemText>Edit Profile</ListItemText>
              </MenuItem>
              <MenuItem onClick={() => navigate("/orders")}>
                <ListItemIcon>
                  <ShoppingBag />
                </ListItemIcon>
                <ListItemText>My Orders</ListItemText>
              </MenuItem>
              <Divider />
              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <Logout />
                </ListItemIcon>
                <ListItemText>Logout</ListItemText>
              </MenuItem>
            </MenuList>
          </Paper>
        </div>

        {/* Profile Details Section */}
        <div className="col-lg-9 col-md-8">
          <Card>
            <CardContent>
              <div className="d-flex align-items-center">
                <input type="file" hidden ref={profilePicRef} onChange={handleSelectProfilePicture} />
                <Avatar 
  src={profilePicture ? validateImageUrl(profilePicture) : null} 
  sx={{ width: 120, height: 120, marginRight: 3 }} 
>
  {!profilePicture && userDetails.name?.charAt(0).toUpperCase()}
</Avatar>
                <div>
                  <Typography variant="h5" fontWeight="bold">
                    {`${userDetails.name.slice(0,1).toUpperCase()}${userDetails.name.slice(1)}`}
                  </Typography>
                  <Typography variant="body1">
                    <Phone fontSize="small" /> {userDetails.phone_number}
                  </Typography>
                </div>
              </div>
              <Divider sx={{ my: 2 }} />
            </CardContent>
          </Card>

          {/* Favorite Meals Section */}
          <Card sx={{ mt: 3 }}>
            <CardContent>
              <Typography variant="h6" sx={{ mb: 2 }}>
                <Favorite fontSize="small" sx={{ color: "red", mr: 1 }} />
                Favorite Meals
              </Typography>
              {favoriteMeals?.length > 0 ? (
                <div className="row">
                  {favoriteMeals?.map((meal) => (
                    <div key={meal.meal.id} className="col-md-4 mb-3">
                      <Card sx={{ maxWidth: 250 }}>
                        <img src={meal.meal.main_image} alt={meal.meal.name} style={{ width: "100%", height: 150, objectFit: "cover" }} />
                        <CardContent>
                          <Typography variant="body1" fontWeight="bold">{meal.meal.name}</Typography>
                          <Typography variant="body2" color="text.secondary">Rs. {meal.meal.price}</Typography>
                        </CardContent>
                        <IconButton color="error" onClick={() => handleRemoveFavorite(meal.meal.id)}>
                          <Delete />
                        </IconButton>
                      </Card>
                    </div>
                  ))}
                </div>
              ) : (
                <Typography variant="body2" color="text.secondary">No favorite meals yet.</Typography>
              )}
            </CardContent>
          </Card>
        </div>
      </div>

      {/* ✅ Edit Profile Modal */}
      <div className="modal fade" id="editProfileModal" tabIndex="-1">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Profile</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
            </div>
            <div className="modal-body">
              <div className="row text-center">
                <button style={{ height: "120px", width: "120px", overflow: "hidden", borderRadius: "50%" }} onClick={() => profilePicRef.current.click()}>
                  <img src={profilePicture} alt="Profile" height={120} width={120} style={{ borderRadius: "50%" }} />
                </button>
              </div>
              <div className="row mt-3">
                <div className="col-sm-6">
                  <label className="form-label">Full Name</label>
                  <input type="text" name="name" className="form-control" value={`${userDetails.name}`} onChange={handleInputChange} />
                </div>
                <div className="col-sm-6">
                  <label className="form-label">Phone Number</label>
                  <input type="text" name="phone_number" className="form-control" value={userDetails.phone_number} onChange={handleInputChange} disabled/>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" onClick={handleUpdateProfile}>
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
