import { configureStore } from "@reduxjs/toolkit";
import  authReducer  from "../reducers/authSlice";
import { authApi } from "../authentication/auth";
import signupReducer from "../reducers/signupSlice";
import {mealApiSlice} from "../reducers/mealSlice";
import cartReducer from '../reducers/cartCountSlice';
const store=configureStore({
    reducer:{
       auth:authReducer,
       signup:signupReducer,
       cart:cartReducer,
       [authApi.reducerPath]:authApi.reducer,
       [mealApiSlice.reducerPath]:mealApiSlice.reducer,

    },
    middleware:(getDefaultMiddleware)=>getDefaultMiddleware().concat(authApi.middleware).concat(mealApiSlice.middleware),
});


export default store;