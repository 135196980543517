export const handleError = (error) => {
  if (!error || !error.data) {
    return "Something went wrong"; // Handle cases where no error data is present
  }

  const { data } = error;

  // 1️⃣ Handle Django DRF Validation Errors (Dictionary format)
  if (typeof data === "object" && !Array.isArray(data)) {
    return Object.values(data)  // Extract error values
      .flat()                   // Flatten nested lists
      .join(', ');              // Convert to a string
  }

  // 2️⃣ Handle `message` field (when it's an object)
  if (data?.message && typeof data.message === "object") {
    return Object.values(data.message)
      .flat()
      .join(', ');
  }

  // 3️⃣ Handle `detail` field (used in authentication, permission errors)
  if (data?.detail) {
    return data.detail;
  }

  // 4️⃣ Handle `message` as a string
  if (typeof data.message === "string") {
    return data.message;
  }

  // 5️⃣ Fallback for unexpected formats
  return "Something went wrong";
};
