import React, { useState } from "react";
import { Button, TextField, ThemeProvider, Typography, Container, InputAdornment } from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { createTheme } from "@mui/material/styles";
import { Phone } from "@mui/icons-material";

// Define MUI theme
export const theme = createTheme({
  palette: {
    primary: {
      main: "#ffbe33", // Primary color (same as Login button)
    },
    secondary: {
      main: "#007bff", // Bootstrap primary color
    },
    error: {
      main: "#d32f2f",
    },
  },
});

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [phone_number, setPhoneNumber] = useState("");
  const bgImage = `${process.env.PUBLIC_URL}/images/hero-bg.png`;
  
  const handleSubmit = async (e) => {
    e.preventDefault();
  // Validate phone number length (should be exactly 10 digits)
  const phoneRegex = /^[0-9]{10}$/;
  if (!phoneRegex.test(phone_number)) {
    toast.error("Please enter a valid 10-digit phone number.");
    return;
  }
    try {
      // API CALL (Replace with actual API request)
      // const response = await forgotPassword({ phone_number }).unwrap();
      // toast.success(response.message);
      // navigate("/reset-password"); // Redirect to reset page
      toast.success("OTP sent successfully to " + phone_number);
    } catch (error) {
      toast.error(error?.data?.message || "Failed to send OTP.");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div 
        className="d-flex align-items-center justify-content-center" 
        style={{ 
          minHeight: "100vh", 
          backgroundImage: `url(${bgImage})`, 
          backgroundSize: "cover", 
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat"
        }}
      >
        <Container maxWidth="sm">
          <div 
            className="p-4 border rounded mx-auto"
            style={{ 
              backgroundColor: "rgba(0,0,0,0.5)", 
              width: "100%", 
              maxWidth: "400px" 
            }}
          >
            <Typography variant="h5" align="center" color="white">
              Forgot Password
            </Typography>
            <Typography variant="body2" align="center" color="white" sx={{ mt: 1 }}>
              Enter your registered phone number to receive a reset OTP.
            </Typography>

            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                label="Phone Number"
                variant="filled"
                margin="normal"
                type="tel"
                value={phone_number}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
                sx={{ backgroundColor: "white" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Phone />
                    </InputAdornment>
                  ),
                }}
              />

              <Button fullWidth variant="contained" color="primary" type="submit" sx={{ mt: 2 }}>
                Send OTP
              </Button>
            </form>

            <Button
              fullWidth
              variant="text"
              color="secondary"
              sx={{ mt: 2, textTransform: "none" }}
              onClick={() => navigate("/login")}
            >
              Back to Login
            </Button>
          </div>
        </Container>
      </div>
    </ThemeProvider>
  );
};

export default ForgotPassword;
