import React, { useEffect, useState } from "react";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff, Phone, Person } from "@mui/icons-material";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { adminUserSchema } from "../schemas/adminSchema";
import { udpateField } from "../reducers/signupSlice";
import { useUserOtpMutation } from "../authentication/auth";
import NavBar from "./components/NavBar";
import { handleError } from "../helper/error";


const AddUserAdmin = () => {
  const dispatch = useDispatch();
  const navigate=useNavigate();
  const bgImage = `${process.env.PUBLIC_URL}/images/hero-bg.png`;
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const[userOtp,{isLoading,error}]=useUserOtpMutation();

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: { name: "", phone_number: "", password: "", confirm_password: "",account_type:"admin" },
    validationSchema: adminUserSchema,
    onSubmit: async (values) => {
      console.log("values: ",values)
      try{
        dispatch(udpateField({values}));
        const{data,error}=await userOtp({phone_number:values.phone_number});
        if(data){
         toast.success(data.message);
         navigate("/otp");
        }
      }catch(error){
       toast.error(handleError(error));
      }
       
    },
  });

  return (
    <div className="container-fluid" style={{ backgroundImage: `url(${bgImage})`, backgroundSize: "cover" }}>
      <NavBar></NavBar>
      <div className="container">
        <div className="row" style={{ height: "100vh" }}>
          <div className="col-md-6 col-12 d-flex flex-column justify-content-center">
            <form onSubmit={handleSubmit} style={{ backgroundColor: "rgba(0,0,0,0.5)", padding: "20px", borderRadius: "10px" }}>
              <h2 className="text-white text-center">Add User</h2>
              <TextField label="Full Name" variant="filled" fullWidth margin="normal" name="name" value={values.name} onChange={handleChange} onBlur={handleBlur} error={touched.name && errors.name} helperText={touched.name && errors.name} sx={{ backgroundColor: "white" }} InputProps={{ endAdornment: (<InputAdornment position="end"><Person /></InputAdornment>) }} />
              <TextField label="Contact Number" variant="filled" fullWidth margin="normal" name="phone_number" value={values.phone_number} onChange={handleChange} onBlur={handleBlur} error={touched.phone_number && errors.phone_number} helperText={touched.phone_number && errors.phone_number} sx={{ backgroundColor: "white" }} InputProps={{ endAdornment: (<InputAdornment position="end"><Phone /></InputAdornment>) }} />
              <TextField label="Password" variant="filled" fullWidth margin="normal" name="password" type={showPassword ? "text" : "password"} value={values.password} onChange={handleChange} onBlur={handleBlur} error={touched.password && errors.password} helperText={touched.password && errors.password} sx={{ backgroundColor: "white" }} InputProps={{ endAdornment: (<InputAdornment position="end"><IconButton onClick={() => setShowPassword(!showPassword)}>{showPassword ? <VisibilityOff /> : <Visibility />}</IconButton></InputAdornment>) }} />
              <TextField label="Confirm Password" variant="filled" fullWidth margin="normal" name="confirm_password" type={showConfirmPassword ? "text" : "password"} value={values.confirm_password} onChange={handleChange} onBlur={handleBlur} error={touched.confirm_password && errors.confirm_password} helperText={touched.confirm_password && errors.confirm_password} sx={{ backgroundColor: "white" }} InputProps={{ endAdornment: (<InputAdornment position="end"><IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)}>{showConfirmPassword ? <VisibilityOff /> : <Visibility />}</IconButton></InputAdornment>) }} />
              <Button variant="contained" type="submit" color="primary" fullWidth disabled={isLoading}>Add User</Button>
             
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUserAdmin;
