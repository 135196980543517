import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, Pagination, IconButton, TextField, Avatar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDeleteUserMutation, useFetchUsersQuery, useSearchUserQuery } from '../reducers/userSlice';
import { Delete, VisibilitySharp, Search } from '@mui/icons-material';
import { toast } from 'react-toastify';
import NavBar from './components/NavBar';
import { useSelector } from 'react-redux';
const UserList = () => {
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    const page_size = 8;

    // ✅ Fetch paginated users
    const { data: users, isLoading, error, refetch } = useFetchUsersQuery({ page, page_size });

    // ✅ Fetch search results when searchTerm is present
    const { data: searchData } = useSearchUserQuery(searchTerm, { skip: !searchTerm });

    // ✅ Delete user mutation
    const [deleteUser] = useDeleteUserMutation();
    

    // ✅ Handle user deletion
    const handleUserDelete = async (id) => {
        const confirm = window.confirm("Are you sure you want to delete this User?");
        if (confirm) {
            try {
                await deleteUser(id);
                toast.success("User deleted successfully...");
                refetch(); // ✅ Refresh user list after delete
            } catch (error) {
                toast.error("Error deleting user. Please try again.");
            }
        }
    };

    // ✅ Decide which data to display: `searchData` if searching, else `users`
    const displayedUsers = searchTerm ? searchData?.results : users?.results;
    // const displayedUsers=users?.users;
    // console.log("displayedusers: ",users?.users)

    if (isLoading) return <CircularProgress />;
    if (error) return <p>Error loading users...</p>;

    return (
        <div className='container-fluid'>
            <NavBar></NavBar>
        <div className="container" style={{marginTop:"100px"}}>
            <h2 className="text-center mb-4" style={{ marginTop: "70px" }}>User List</h2>
            {/* ✅ Search Bar */}
            <div className="d-flex justify-content-between mb-3">
                <TextField
                    variant="outlined"
                    placeholder="Search users..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    InputProps={{
                        startAdornment: <Search />,
                    }}
                    style={{ width: "300px" }}
                />
            </div>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow style={{ backgroundColor: "#f5f5f5" }}>
                            <TableCell><strong>Profile</strong></TableCell>
                            <TableCell><strong>Name</strong></TableCell>
                            <TableCell><strong>Contact Number</strong></TableCell>
                            <TableCell><strong>Role</strong></TableCell>
                            <TableCell><strong>Created At</strong></TableCell>
                            <TableCell><strong>Actions</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {displayedUsers?.length > 0 ? (
                            displayedUsers.map((user) => (
                                <TableRow key={user.id}>

<TableCell>
    {user.profile_picture ? (
        <img 
            src={user.profile_picture} 
            alt={user.name} 
            height={60} 
            width={60} 
            style={{ borderRadius: '50%' }} 
        />
    ) : (
        <Avatar sx={{ bgcolor: "#e69c00", color: "white", width: 60, height: 60, fontSize: "20px" }}>
            {user.name.split(" ").map(word => word[0]).join("").toUpperCase()}
        </Avatar>
    )}
</TableCell>

<TableCell>
    {user.name
        .split(" ")
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ")
    }
</TableCell>

                                    <TableCell>{user.phone_number}</TableCell>
                                    <TableCell>{user.is_admin ? "Admin" : "User"}</TableCell>
                                    <TableCell>{new Date(user.created_at).toLocaleDateString()}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => navigate(`/route/admin/view-user/${user.id}`)}>
                                            <VisibilitySharp />
                                        </IconButton>
                                        <IconButton color='error' onClick={() => handleUserDelete(user.id)}>
                                            <Delete />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={6} className="text-center">
                                    No users found.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* ✅ Show Pagination ONLY if not searching */}
            {!searchTerm && (
                <div className="d-flex justify-content-center mt-3">
                    <Pagination
                        count={Math.ceil(users?.count / page_size) || 1}
                        page={page}
                        onChange={(event, value) => setPage(value)}
                        color="primary"
                    />
                </div>
            )}
        </div>
        </div>
    );
};

export default UserList;
