import { authApi } from "../authentication/auth"

export const mealApiSlice=authApi.injectEndpoints({
    endpoints:(builder)=>{
        return {
            createMealCategory:builder.mutation({
                query:(categoryData)=>({
                    url:'/meal/categories/',
                    method:'POST',
                    body:categoryData
                })
            }),
            fetchMealCategory:builder.query({
                query:()=>({
                    url:'/meal/categories/',
                    method:"GET"
                })
            }),
            createMeal:builder.mutation({
                query:(mealData)=>({
                    url:'/meal/meals/',
                    method:'POST',
                    body:mealData
                })
            }),
            fetchMealById:builder.query({
                query:(id)=>({
                    url:`/meal/meals/${id}`,
                    method:"GET",
                })
            }),
            updateMeal:builder.mutation({
                query:({id,updateData})=>({
                    url:`/meal/${id}/update/`,
                    method:'PATCH',
                    body:updateData,
                })
            }),
            fetchMeals:builder.query({
                query:({page,page_size})=>({
                    url:`/meal/meals/?page=${page}&page_size=${page_size}`,
                    method:"GET"
                })
            }),
            deleteMeal:builder.mutation({
                query:(id)=>({
                    url:`/meal/${id}/delete/`,
                    method:"DELETE"
                }),
                invalidatesTags:["Meals"]
            }),
            searchMeal:builder.query({
                query:(searchtext)=>({
                    url:`/meal/search/?search=${searchtext}`,
                    method:"GET"
                })
            }),
            reviewRatingMeal:builder.mutation({
                query:(reviewData)=>({
                    url:'/meal/review-rating/',
                    method:"POST",
                    body:reviewData
                })
            }),
            fetchReviewRating:builder.query({
                query:(meal_id)=>({
                    url:`/meal/review-rating/?meal_id=${meal_id}`,
                    method:'GET'
                })
            }),
            favoriteMeal:builder.mutation({
                query:(favoriteData)=>({
                    url:'/meal/favorite-meal/',
                    method:"POST",
                    body:favoriteData
                })
            }),
            fetchUserFavoriteByMealId:builder.query({
                query:(meal_id)=>({
                    url:`/meal/favorite-meal/?meal_id=${meal_id}`,
                    method:"GET"
                })
            }),
            fetchLatestMeal:builder.query({
                query:()=>({
                  url:'/meal/latest-meal/',
                  method:"GET"  
                })
            }),
            deleteMealCategory:builder.mutation({
                query:(category_id)=>({
                    url:`/meal/categories/${category_id}/`,
                    method:'DELETE'
                })
            }),
            updateMealCategory:builder.mutation({
                query:({id,name})=>({
                    url:`/meal/categories/${id}/`,
                    method:'PATCH',
                    body:name
                })
            }),
            fetchMealByCategoryId:builder.query({
                query:(category_id)=>({
                  url:`/meal/meal-by-category/${category_id}/`,
                  method:'GET',
                })
            }),
            fetchAllMeal:builder.query({
                query:()=>({
                    url:'/meal/meals/',
                    method:"GET"
                })
            }),
            fetchUserFavoriteMeal:builder.query({
                query:()=>({
                    url:"/meal/favorite-meal/",
                    method:"GET"
                })
            }),
            removeFavoriteMeal:builder.mutation({
                query:(id)=>({
                  url:"/meal/favorite-meal/",
                  method:"DELETE",
                  body:{meal:id}
                })
            }),


            
        }
    }
})


export const {useCreateMealCategoryMutation,useFetchMealCategoryQuery,useCreateMealMutation,useFetchMealByIdQuery,useUpdateMealMutation,useFetchMealsQuery,useDeleteMealMutation,useSearchMealQuery,useReviewRatingMealMutation,useFetchReviewRatingQuery,useFavoriteMealMutation,useFetchUserFavoriteByMealIdQuery,useFetchLatestMealQuery,useDeleteMealCategoryMutation,useUpdateMealCategoryMutation,useFetchMealByCategoryIdQuery,useFetchAllMealQuery,useFetchUserFavoriteMealQuery,useRemoveFavoriteMealMutation}=mealApiSlice;