import React, { useEffect, useRef, useState } from 'react';
import { Button, Checkbox, TextField, FormControlLabel, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';
import { useFetchMealCategoryQuery, useCreateMealMutation } from '../reducers/mealSlice';
import { mealSchema } from '../schemas/mealSchema';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import NavBar from './components/NavBar';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const AddMeal = () => {
    const bgImage = `${process.env.PUBLIC_URL}/images/hero-bg.png`;
    const addImage = `${process.env.PUBLIC_URL}/images/addimage.png`;
    const { data: mealCategories, isLoading: isFetching, error: fetchError, refetch } = useFetchMealCategoryQuery();
    const [createMeal, { isLoading }] = useCreateMealMutation();

    console.log("mealCategories: ", mealCategories);

    const mainImageRef = useRef();
    const image1Ref = useRef();
    const image2Ref = useRef();

    //  Formik setup
    const formik = useFormik({
        initialValues: {
            name: '',
            price: '',
            half_plate_price: '', // Added this field
            ingredients: '',
            description: '',
            main_image: null,
            image1: null,
            image2: null,
            category_id: '',
            meal_type: 'Plate', // Default to Plate
            is_non_veg: false,
        },
        validationSchema: mealSchema,
        onSubmit: async (values) => {
            console.log("Submitting Meal Data:", values);
            const formData = new FormData();
            Object.keys(values).forEach((key) => {
                if (values[key]) { 
                    if (typeof values[key] === "boolean") {
                        formData.append(key, values[key] ? "true" : "false");
                    } else if (values[key] instanceof File) {
                        formData.append(key, values[key]);
                    } else {
                        formData.append(key, values[key]);
                    }
                }
            });

            try {
                const response = await createMeal(formData);
                if (response?.data) {
                    toast.success("Meal created successfully..");
                }
            } catch (error) {
                toast.error("Unexpected error: ", error.response);
            }
        },
    });

    //  Image preview handling
    const [previewImage, setPreviewImage] = useState({
        main_image: '',
        image1: '',
        image2: '',
    });

    const handleImageSelect = (e) => {
        const { name } = e.target;
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewImage((prev) => ({
                    ...prev,
                    [name]: reader.result, // Set preview image
                }));
            };
            reader.readAsDataURL(file);
            formik.setFieldValue(name, file);
        }
    };

    useEffect(() => {
        refetch();
    }, [refetch]);

    return (
        <div className='container-fluid h-100' style={{ backgroundImage: `url(${bgImage})`, backgroundSize: "cover"}}>
            <div className='container'>
                <NavBar></NavBar>
                <div className='row' style={{ marginTop: "100px" }}>
                    <div className='col-sm-8'>
                        <form onSubmit={formik.handleSubmit}>
                            <div className='row'>
                                <h2 className='text-white fs-3 text-center' style={{ marginBottom: "0" }}>Add New Meal</h2>
                            </div>
                            <div className='row'>

                                {/* Meal Name */}
                                <div className='col-sm-12'>
                                    <TextField
                                        label="Meal Name"
                                        variant='filled'
                                        fullWidth
                                        margin='normal'
                                        name='name'
                                        type='text'
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.name && Boolean(formik.errors.name)}
                                        helperText={formik.touched.name && formik.errors.name}
                                        sx={{ backgroundColor: 'white' }}
                                    />
                                </div>

                                {/* Meal Price */}
                                <div className='col-sm-12'>
                                    <TextField
                                        label="Full plate Price / One Piece Price"
                                        fullWidth
                                        variant='filled'
                                        margin='normal'
                                        name='price'
                                        type='text'
                                        value={formik.values.price}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.price && Boolean(formik.errors.price)}
                                        helperText={formik.touched.price && formik.errors.price}
                                        sx={{ backgroundColor: 'white' }}
                                    />
                                </div>

                                {/* Meal Type Select */}
                                <div className='col-sm-12'>
                                    <FormControl fullWidth variant='filled' sx={{ backgroundColor: "white", marginTop: "16px" }}>
                                        <InputLabel>Meal Type</InputLabel>
                                        <Select
                                            name="meal_type"
                                            value={formik.values.meal_type}
                                            onChange={(e) => {
                                                formik.setFieldValue("meal_type", e.target.value);
                                                if (e.target.value === "Piece") {
                                                    formik.setFieldValue("half_plate_price", ""); 
                                                }
                                            }}
                                        >
                                            <MenuItem value="Plate">Plate</MenuItem>
                                            <MenuItem value="Piece">Piece</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>

                                {/* Half Plate Price (Enabled only if meal_type is Plate) */}
                                <div className='col-sm-12'>
                                    <TextField
                                        label="Half Plate Price"
                                        fullWidth
                                        variant='filled'
                                        margin='normal'
                                        name='half_plate_price'
                                        type='text'
                                        value={formik.values.half_plate_price}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        disabled={formik.values.meal_type === "Piece"} // Disable when meal_type is "Piece"
                                        error={formik.touched.half_plate_price && formik.values.meal_type==="Plate" && Boolean(formik.errors.half_plate_price)}
                                        helperText={formik.touched.half_plate_price && formik.values.meal_type==="Plate" &&  formik.errors.half_plate_price}
                                        sx={{ backgroundColor: formik.values.meal_type === "Piece" ? '#f0f0f0' : 'white' }}
                                    />
                                </div>
                         
                           {/*  Meal Category */}
                        <div className='col-sm-12'>
                            <FormControl fullWidth sx={{ marginTop: "16px",
                            marginBottom:"20px",
                                backgroundColor: "white" }} variant='filled'>
                                <InputLabel id="category" error={formik.touched.category_id && Boolean(formik.errors.category_id)}>Meal Category</InputLabel>
                                <Select
                                    labelId='category'
                                    name="category_id"
                                    value={formik.values.category_id}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    sx={{ backgroundColor: "white" }}
                                >
                                    {mealCategories?.results?.map((category) => (
                                        <MenuItem value={category.id} key={category.id}>{category.name}</MenuItem>
                                    ))}
                                </Select>
                                {formik.touched.category_id && formik.errors.category_id && (
                                    <FormHelperText sx={{ color: 'red' }}>{formik.errors.category_id}</FormHelperText>
                                )}
                            </FormControl>
                        </div>


                                  
                         {/*  Meal Ingredients */}
                        <div className='col-sm-12'>
                            <TextField
                                label="Meal Ingredients"
                                variant='filled'
                                fullWidth
                                multiline
                                rows={2}
                                name='ingredients'
                                value={formik.values.ingredients}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.ingredients && Boolean(formik.errors.ingredients)}
                                helperText={formik.touched.ingredients && formik.errors.ingredients}
                                sx={{ backgroundColor: 'white',
                                marginBottom:"20px"

                                }}
                            />
                        </div>


                         {/*  Meal Description */}
                         <div className='col-sm-12'>
                            <TextField
                                label="Meal Description"
                                variant='filled'
                                fullWidth
                                multiline
                                rows={2}
                                name='description'
                                value={formik.values.description}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.description && Boolean(formik.errors.description)}
                                helperText={formik.touched.description && formik.errors.description}
                                sx={{ backgroundColor: 'white' }}
                            />
                        </div>


                                 {/*  Is Non Veg Checkbox */}
                        <div className='col-sm-12'>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="is_non_veg"
                                        checked={formik.values.is_non_veg}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        sx={{ color: "white", '&.Mui-checked': { color: "white" } }}
                                    />
                                }
                                label="Is Non Veg"
                                sx={{ color: "white" }}
                            />
                        </div>

                                {/* Meal Images */}
                                <div className='row'>
                                    {["main_image", "image1", "image2"].map((imgKey, index) => (
                                        <div className='col-md-4' key={imgKey}>
                                            <label className='text-white mt-2'>Meal {index === 0 ? "Main" : "Extra"} Image</label>
                                            <button type="button" className='btn btn-secondary mt-2' style={{ height: "150px", width: "150px", padding: "0" }}
                                                onClick={() => (imgKey === "main_image" ? mainImageRef : imgKey === "image1" ? image1Ref : image2Ref).current.click()}>
                                                <img src={previewImage[imgKey] || addImage} style={{ height: "100%", width: "100%", objectFit: "cover" }} alt='Preview' />
                                            </button>
                                            <input type='file' name={imgKey} className='d-none' ref={imgKey === "main_image" ? mainImageRef : imgKey === "image1" ? image1Ref : image2Ref} onChange={handleImageSelect} />
                                        </div>
                                    ))}
                                </div>

                                {/* Submit Button */}
                                <div className='col-sm-3 m-3'>
                                     <Button className='mt-2' variant='contained' disabled={isLoading} sx={{ backgroundColor: "#ffbe33", color: "white" }} type='submit'>
                                                                    {isLoading ? 'Loading..' : 'Add Meal'}
                                                                </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddMeal;
