import React from "react";
import { Link } from "react-router-dom";
import NavBar from "../components/NavBar";
const FeatureUnavailable = () => {
  return (
    <div className="container-fluid" style={{padding:"0px"}}>
      <NavBar></NavBar>
    <div style={styles.container}>
      <h1 style={styles.heading}>🚧 Feature Unavailable 🚧</h1>
      <p style={styles.text}>
        This feature is not available right now. Please check back later.
      </p>
      <Link to="/" style={styles.link}>Go Back to Home</Link>
    </div>
    </div>
  );
};

const styles = {
  container: {
    textAlign: "center",
    padding: "50px",
    fontFamily: "Arial, sans-serif",
  },
  heading: {
    fontSize: "30px",
    color: "#ff4c4c",
    fontWeight: "bold",
  },
  text: {
    fontSize: "18px",
    color: "#666",
    margin: "20px 0",
  },
  link: {
    textDecoration: "none",
    fontSize: "20px",
    color: "#ffbe33",
    fontWeight: "bold",
  },
};

export default FeatureUnavailable;
