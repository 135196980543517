import { authApi } from "../authentication/auth";

export const aboutApi=authApi.injectEndpoints({
    endpoints:(builder)=>{
        return{
           fetchAbout:builder.query({
            query:()=>({
                url:"/about/about/",
                method:"GET"
            })
           }),
           createUpdateAbout:builder.mutation({
            query:(aboutData)=>({
                url:'/about/about/',
                method:"POST",
                body:aboutData

            })
           }),
        }
    }
});

export const {useFetchAboutQuery,useCreateUpdateAboutMutation}=aboutApi;