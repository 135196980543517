import * as Yup from 'yup';

export const aboutSchema = Yup.object().shape({
        description: Yup.string()
        .trim()
        .min(10, 'Description must be at least 10 characters')
        .max(3000, 'Description cannot exceed 3000 characters')
        .required('Restaurant description is required'),
    
        contact_number: Yup.string()
        .trim().required('Contact number is required'),
    
       email: Yup.string()
        .trim()
        .email('Invalid email format')
        .required('Email is required'),
    
        image: Yup.mixed()
        .test('fileType', 'Unsupported file format', (value) => {
            if (!value) return true;
            return ['image/jpeg', 'image/png', 'image/jpg'].includes(value.type);
        })
        .nullable(),
    
    opening_time: Yup.string()
        .trim()
        // .matches(/^([0-9]{2}):([0-9]{2})$/, 'Invalid time format')
        .required('Opening time is required'),
    
    closing_time: Yup.string()
        .trim()
        // .matches(/^([0-9]{2}):([0-9]{2})$/, 'Invalid time format')
        .required('Closing time is required'),
    
    facebook_url: Yup.string()
        .trim()
        .url('Enter a valid URL')
        .nullable(),
    
    instagram_url: Yup.string()
        .trim()
        .url('Enter a valid URL')
        .nullable(),
    
    tiktok_url: Yup.string()
        .trim()
        .url('Enter a valid URL')
        .nullable(),
});
