import * as Yup from "yup";

export const reviewRatingSchema = Yup.object({
    rating: Yup.number()
        .min(1, "Rating must be at least 1.0")
        .max(5, "Rating cannot exceed 5.0")
        .required("Rating is required"),

    review: Yup.string()
        .min(10, "Review must be at least 10 characters long")
        .max(500, "Review cannot exceed 500 characters")
        .required("Review is required")
});
