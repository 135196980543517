// Define the function
function validateImageUrl(url) {
  // If URL is null, undefined, or empty, return a default placeholder image
  if (!url) {
    return "https://via.placeholder.com/150"; // Default image placeholder
  }

  // Check if the URL starts with 'http://' or 'https://'
  if (url.startsWith("http://") || url.startsWith("https://")) {
    return url; // Return the URL unchanged
  } else {
    // Prepend 'http://localhost:8000' to the URL
    return `https://api.pakizarestaurant.com${url}`;
  }
}

// Export the function
export { validateImageUrl };
