import React, { useEffect, useRef, useState } from 'react';
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useUserSignupMutation } from '../authentication/auth';
import { toast } from 'react-toastify';
import { handleError } from '../helper/error';
import { useCreateUserAdminMutation, useResendOtpMutation } from '../reducers/userSlice';

const Otp = () => {
    const navigate = useNavigate();
    const userData = useSelector((state) => state.signup);
    const bgImage = `${process.env.PUBLIC_URL}/images/hero-bg.png`;
    const emailLogo = `${process.env.PUBLIC_URL}/images/email.png`;
    const otpInputs = Array.from({ length: 4 }, (_, index) => index + 1);
    const [userSignup, { isLoading, error }] = useUserSignupMutation();
    const [createAdmin, { isLoading: createAdminLoading }] = useCreateUserAdminMutation();
    const otpRef = useRef(otpInputs.map(() => React.createRef()));
    const [timeRemaining, setTimeRemaining] = useState(300);
    const [disable, setDisable] = useState(false);
    const timerRef = useRef(null);
    const [resendOtp,{isLoading:loadingResendOTP,erorr:errorResendOtp}]=useResendOtpMutation();
    useEffect(() => {
      if (timeRemaining > 0) {
          timerRef.current = setTimeout(() => {
              setTimeRemaining(prev => prev - 1);
          }, 1000);
      } else {
          setDisable(true); // Disable verification button after timeout
      }
  
      return () => clearTimeout(timerRef.current); // Cleanup timer
  }, [timeRemaining]);
    // useEffect(() => {
    //     if (!userData.phone_number) {
    //         navigate("/signup");
    //     }
    // }, [navigate, userData]);
    
    const handleInputChange = (index, e) => {
      const input = e.target;
      const value = input.value;
      
      // Ensure only numbers are entered
      if (!/^\d?$/.test(value)) {
          input.value = ""; // Clear input if non-numeric character is entered
          return;
      }
  
      // Move to the next input field automatically if a digit is entered
      if (value.length === 1 && index < otpInputs.length - 1) {
          otpRef.current[index + 1].current.focus();
      }
  };
  
    

  const handleKeyDown = (index, e) => {
    if (e.key === 'Backspace' && !e.target.value && index > 0) {
        otpRef.current[index - 1].current.focus();
    }
};



    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    };
   
    const handleResendOtp = async () => {
      try {
          const response = await resendOtp({ phone_number: userData.phone_number, otp_type: userData.account_type }).unwrap();
          
          if (response.message) {
              toast.success(response.message);
              
              // Reset timer to 5 minutes (300 seconds)
              setTimeRemaining(300);
              setDisable(false); // Re-enable verification button
          }
      } catch (error) {
          toast.error(error?.data?.message || "Failed to resend OTP. Try again later.");
      }
  };
    const handleSignUp = async () => {
        try {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
                timerRef.current = null;
            }
            if (!userData.phone_number) {
                navigate("/signup");
                return;
            }

            const otpString = otpRef.current.map(ref => ref.current.value).join("");
            const signupData = {
                phone_number: userData.phone_number,
                name: userData.name,
                password: userData.password,
                userotp: otpString,
                account_type: userData.account_type
            };
            
            let response;
            if (userData.account_type === "admin") {
                response = await createAdmin(signupData);
            } else {
                response = await userSignup(signupData);
            }
            
            if (response.data) {
                toast.success(response.data.message);
                navigate('/');
            } else if (response.error) {
                toast.error(handleError(response.error));
            }
        } catch (err) {
            toast.error("An unexpected error occurred. Please try again.");
        }
    };

    return (
        <div className='container-fluid' style={{ backgroundImage: `url(${bgImage})`, backgroundSize: "cover" }}>
            <div className='container'>
                <div className='row' style={{ minHeight: '100vh' }}>
                    <div className='col-md-6'></div>
                    <div className='col-md-6 col-12 d-flex flex-column justify-content-center'>
                        <div className='row' style={{
                            backgroundColor: 'rgba(0,0,0,0.5)',
                            boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px'
                        }}>
                            <div className='row mt-4 d-flex justify-content-center'>
                                <div className='col-sm-6 d-flex justify-content-center'>
                                    <img src={emailLogo} height={80} width={80} alt='email icon'></img>
                                </div>
                            </div>
                            <div className='row mt-4 d-flex justify-content-center'>
                                <div className='col-sm-6'>
                                    <h4 className='text-white text-center'>OTP Verification</h4>
                                </div>
                            </div>
                            <div className='row d-flex justify-content-center'>
                                <div className='col-sm-6'>
                                    <p className='text-white text-center' style={{ fontSize: "13px" }}>We've sent a code to</p>
                                    <p className='text-center' style={{ fontSize: "13px", color: "#ffbe33" }}>{userData.phone_number}</p>
                                </div>
                            </div>
                            <div className='row my-2 d-flex justify-content-center align-items-center'>
                                {otpInputs.map((index) => (
                                    <div className='col-sm-2' key={index}>
                                        <input
                                            ref={otpRef.current[index - 1]}
                                            type='text'
                                            maxLength='1'
                                            className='form-control'
                                            onChange={(e) => handleInputChange(index - 1, e)}
                                            onKeyDown={(e)=>handleKeyDown(index-1,e)}
                                            style={{ height: "80px", fontSize: "30px", fontWeight: "bold", textAlign: "center", color: "#ffbe33" }}
                                        />
                                    </div>
                                ))}
                            </div>
                            <div className='row mt-2 d-flex justify-content-center'>
                                <div className='col-sm-6'>
                                    <p className='text-white text-center'>Time Remaining <span style={{ color: `${timeRemaining > 60 ? "#ffbe33" : "red"}` }}>{formatTime(timeRemaining)}</span></p>
                                </div>
                            </div>
                            <div className='row my-4 d-flex justify-content-center'>
                                <div className='col-sm-6 d-flex justify-content-between'>
                                    <Button variant='contained' color='error'>Cancel</Button>
                                    {!disable ? (
    <Button 
        variant='contained' 
        sx={{ backgroundColor: '#ffbe33', color: '#fff' }} 
        onClick={handleSignUp}
    >
        Verify
    </Button>
) : (
    <Button 
        variant='contained' 
        disabled={loadingResendOTP}
        sx={{ backgroundColor: '#ffbe33', color: '#fff' }} 
        onClick={handleResendOtp}
    >
        Resend OTP
    </Button>
)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Otp;
