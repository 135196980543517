import React, { useEffect, useState } from 'react';
import NavBar from '../components/NavBar';
import { Avatar, Button, Rating, TextField } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useFavoriteMealMutation,  useFetchMealByIdQuery, useFetchReviewRatingQuery, useFetchUserFavoriteByMealIdQuery, useReviewRatingMealMutation } from '../reducers/mealSlice';
import { useFormik } from 'formik';
import { reviewRatingSchema } from '../schemas/reviewRatingSchema';
import { toast } from 'react-toastify';
import { useCreateCartMutation } from '../reducers/cartSlice';
import { useSelector } from 'react-redux';


const Meal = () => {
    const { id } = useParams();
    const authData=useSelector((state)=>state.auth)
    // API Queries & Mutations
    const { data: meal, isLoading, error, refetch } = useFetchMealByIdQuery(id);
    const{data:userFavorite,isLoading:userFavoriteLoading,error:userFavoriteError,refetch:fetchUserFavorite}=useFetchUserFavoriteByMealIdQuery(id);
    const [reviewRatingPost, { isLoading: loadingReviewRating }] = useReviewRatingMealMutation();
    const { data: reviewRatingData, isLoading: fetchReviewLoading } = useFetchReviewRatingQuery(id);
    const [favoriteMeal,{isLoading:postFavoriteMealLoading}]=useFavoriteMealMutation();
    console.log("favdata: ",userFavorite)
    const [activeIndex, setActiveIndex] = useState(0);




    // Manage Carousel Active Slide
    useEffect(() => {
        const carousel = document.getElementById("carouselExampleDark");
        const handleSlide = (event) => setActiveIndex(parseInt(event.to));
        carousel?.addEventListener("slid.bs.carousel", handleSlide);
        return () => carousel?.removeEventListener("slid.bs.carousel", handleSlide);
    }, []);

    // Formik for Review Submission
    const formik = useFormik({
        initialValues: { rating: 0, review: '' },
        validationSchema: reviewRatingSchema,
        onSubmit: async (values, { resetForm }) => {
            try {
                await reviewRatingPost({ meal_id: id, ...values });
                toast.success("Review submitted successfully!");
                resetForm();
                refetch(); 
            } catch (error) {
                toast.error("Error submitting review");
            }
        }
    });

    const handleFavorite=async(mealId)=>{
      console.log("meal id: ",mealId)
      if(!authData.is_authenticated){
        toast.warning("please login first..");
        return;
      }
      try{
     const response=await favoriteMeal({meal:mealId});
     console.log("response: ",response);
     toast.success(response.data.message);
     fetchUserFavorite();
      }catch(error){
      toast.error(error.response.data.message)
      }

    }


      const [createCart, { isLoading:createCartLoading, error:createCartError }] = useCreateCartMutation();
      
      const addToCart = async (mealId) => {
        if(authData.is_admin){
            toast.warning("you can't perform this action as admin...")
            return;
        }
        if(!authData.is_authenticated){
                toast.warning("please Login first...");
                return;
        }

        try {
          const response = await createCart({ meal: mealId }).unwrap(); // Call the API
          toast.success(response.message || "Meal added to cart!"); // Show success message
        } catch (err) {
          if (err?.data?.message) {
            toast.warning(err.data.message); // Show error message if provided
          } else {
            toast.error("Failed to add meal to cart. Please try again.");
          }
        }
      };

    if (isLoading) return <p>Loading...</p>;
    if (error) return <p>Error loading meal details.</p>;

    const images = [meal?.main_image, meal?.image1, meal?.image2];

    return (
        <div className='container-fluid' style={{ padding: "0px" }}>
            <NavBar />
            <div className='container mt-4'>

                {/* ===================== Meal Details Section ===================== */}
                <div className='row'>
                    {/* Left Side - Meal Images */}
                    <div className='col-md-6'>
                        <div id="carouselExampleDark" className="carousel slide">
                            <div className="carousel-inner">
                                {images.map((image, index) => (
                                    <div className={`carousel-item ${index === 0 ? "active" : ""}`} key={index}
                                    style={{ backgroundColor: '#000' }}
                                    >
                                        <img src={image} className="d-block w-100" height={500} alt={`Meal ${index}`} style={{objectFit:"contain"}} />
                                    </div>
                                ))}
                            </div>
                            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
                                <span className="carousel-control-prev-icon"></span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
                                <span className="carousel-control-next-icon"></span>
                            </button>
                        </div>

                        {/* Thumbnail Images */}
                        <div className="row d-flex justify-content-center border mt-3">
                            {images.map((image, index) => (
                                <img
                                    key={index}
                                    src={image}
                                    alt={`thumbnail-${index}`}
                                    height={80}
                                    style={{
                                        width: "80px",
                                        border: activeIndex === index ? "2px solid #ffbe33" : "none",
                                        cursor: "pointer",
                                        margin: "5px"
                                    }}
                                />
                            ))}
                        </div>
                    </div>

                    {/* Right Side - Meal Info */}
                    <div className='col-md-6 p-4 d-flex flex-column'>
                        <div className='row d-flex justify-content-between'>
                            <p className='w-50 fw-bold text-secondary'>{meal.category.name}</p>
                            <Rating name="half-rating-read" defaultValue={2.5} precision={0.5} readOnly />
                        </div>

                        <div className='row d-flex align-items-center'>
                            <div className='w-auto'><h2>{meal.name}</h2></div>
                            <div className='w-auto'>
                                <span className={meal.is_non_veg ? 'bg-warning badge mb-2' : 'bg-success badge mb-2'}>
                                    {meal.is_non_veg ? 'Non Veg' : 'Veg'}
                                </span>
                            </div>
                        </div>

                        <p className='fw-bold fs-4' style={{ color: "#EB0029" }}>Rs {parseInt(meal.price)} <span className='text-secondary' style={{
                            fontSize:"16px",
                        }}>per {meal.meal_type==="Plate" ? "plate" : "piece"}</span> </p>
                        {meal.meal_type === "Plate" && <p className='fw-bold' style={{ color: "#EB0029" }}>Rs {parseInt(meal.half_plate_price)} <span className='text-secondary' style={{fontSize:"14px"}}>half plate</span></p>}
                            
                        <div className='row mb-2'>
                            <p className='text-secondary fw-bold w-auto'>Ingredients:</p>
                            <p className='w-auto'>
                                {meal.ingredients.split(',').map(word => word.trim().charAt(0).toUpperCase() + word.trim().slice(1)).join(', ')}
                            </p>
                        </div>

                        <p className='text-secondary mt-2'>{meal.description}</p>

                        <div className='row d-flex align-items-center'>
                            <div className='w-auto'>
                                <button className='btncls' onClick={()=>addToCart(meal.id)}>Add to cart</button>
                            </div>
                            <div className='w-auto mt-3'>
                                <button className={ userFavorite?.is_favorited ?'p-2 favbtn': 'notfav p-2'} style={{ borderRadius: "50%" }} onClick={()=>handleFavorite(meal.id)}>
                                    <span className='bi bi-heart fs-4'></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* ===================== Review Form ===================== */}
                <div className='row' style={{ marginTop: "100px" }}>
                    <p className='fw-bold' style={{ fontSize: "20px" }}>Add a review</p>
                    <form onSubmit={formik.handleSubmit}>
                        <Rating
                            name="rating"
                            value={formik.values.rating}
                            precision={0.5}
                            onChange={(e, newValue) => formik.setFieldValue('rating', newValue)}
                        />
                        {formik.touched.rating && formik.errors.rating && (
                            <p className='text-danger'>{formik.errors.rating}</p>
                        )}

                        <TextField
                            label="Review"
                            variant='outlined'
                            fullWidth
                            multiline
                            rows={2}
                            name='review'
                            value={formik.values.review}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            sx={{ backgroundColor: 'white' }}
                        />
                        {formik.touched.review && formik.errors.review && (
                            <p className='text-danger'>{formik.errors.review}</p>
                        )}

                        <div className='w-auto'>
                            <Button className='mt-2' variant='contained' sx={{ backgroundColor: "#ffbe33", color: "white" }} type='submit'>
                                {loadingReviewRating ? 'Submitting...' : 'Add Review'}
                            </Button>
                        </div>
                    </form>
                </div>

                {/* ===================== Reviews Section ===================== */}
                <div className='row' style={{ marginTop: "50px" }}>
                    <p className='fw-bold' style={{ fontSize: "20px" }}>Reviews</p>

                    {fetchReviewLoading ? (
                        <p>Loading reviews...</p>
                    ) : reviewRatingData?.results?.length > 0 ? (
                        reviewRatingData.results.map((review) => (
                            <div className='d-flex mb-3' key={review.id}>
                                <div className='w-auto me-2'>
                                    <Avatar>{review.user.name.charAt(0).toUpperCase()}</Avatar>
                                </div>

                                <div className='d-flex w-auto flex-column'>
                                    <div className='w-auto d-flex align-items-center'>
                                        <p className='fw-bold me-2' style={{marginBottom:"0"}}>{`${review.user.name.charAt(0).toUpperCase()}${review.user.name.slice(1)}`}</p>
                                        <Rating
                                            name="read-only"
                                            value={review.rating}
                                            precision={0.5}
                                            readOnly
                                        />
                                    </div>

                                    <p className='text-dark opacity-50'>{new Date(review.created_at).toLocaleDateString()}</p>
                                    <p className='text-secondary'>{review.review}</p>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p className='text-secondary'>No reviews yet.</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Meal;
