import React, { useEffect } from 'react';
import { Avatar, Badge, IconButton } from '@mui/material';
import { Search, ShoppingCart } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFetchUserCartQuery } from '../reducers/cartSlice';
import { setCartCount } from '../reducers/cartCountSlice';
import { useUserProfileQuery} from "../reducers/userSlice";
import { validateImageUrl } from '../helper/validate';
const NavBar = () => {
    const dispatch = useDispatch();
    const cartCount = useSelector((state) => state.cart.cartCount); // Get cart count from Redux store
    const { data: cartData, isLoading, error, refetch } = useFetchUserCartQuery();
    const {data:userDetails,isLoading:userDetailLoading,error:userDetailError}=useUserProfileQuery();
    const is_admin = useSelector((state) => state.auth.is_admin);
    const is_authenticated = useSelector((state) => state.auth.is_authenticated);
    const bgImage = `${process.env.PUBLIC_URL}/images/hero-bg.png`;
    const navigate = useNavigate();
    const location = useLocation();
    
    // ✅ Fetch cart items and update count in Redux
    useEffect(() => {
        refetch();
        if (!isLoading && cartData) {
            dispatch(setCartCount(cartData.length)); // ✅ Update Redux with cart count
        }
    }, [cartData, isLoading, dispatch, refetch]);

    return (
        <nav className="navbar bg-dark navbar-expand-lg custom_nav-container sticky-top bg-body-tertiary"
            style={{
                backgroundImage: `url(${bgImage})`,
                backgroundSize: 'cover',
                paddingLeft: "10px",
                paddingRight: "10px",
                paddingTop: "5px",
                paddingBottom: "5px"
            }} data-bs-theme="dark">
            
            {/* Brand Logo */}
            <Link className="navbar-brand" to="/">
                <img src={`${process.env.PUBLIC_URL}/newlogo.png`} alt='logo' height={80} width={210} style={{ objectFit: "contain", display: "block", maxWidth: "100%" }} />
            </Link>

            {/* Navbar Toggle (for mobile) */}
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            {/* Navbar Content */}
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                {/* Navbar Links */}
                <ul className="navbar-nav me-auto">
                    <li className={`nav-item ${location.pathname === '/' ? 'active' : ''}`}>
                        <Link className="nav-link" to="/">Home</Link>
                    </li>
                    <li className={`nav-item ${location.pathname === '/menu' ? 'active' : ''}`}>
                        <Link className="nav-link" to="/menu">Menu</Link>
                    </li>
                    <li className={`nav-item ${location.pathname === '/about' ? 'active' : ''}`}>
                        <Link className="nav-link" to="/about">About</Link>
                    </li>
                    <li className={`nav-item ${location.pathname === '/booktable' ? 'active' : ''}`}>
                        <Link className="nav-link" to="/featureUnavailable">Book Table</Link>
                    </li>

                    {/* Admin Link */}
                    {is_admin && (
                        <li className="nav-item">
                            <Link className="nav-link" to="/route/admin/meal-list">Admin</Link>
                        </li>
                    )}

                    {/* Login Link if not authenticated */}
                    {!is_authenticated && (
                        <li className="nav-item">
                            <Link className="nav-link" to="/login">Login</Link>
                        </li>
                    )}
                </ul>

                {/* ✅ Right Aligned User Options */}
                <div className="user_option d-flex align-items-center ms-auto">
                    {/* Search Icon */}
                    <IconButton onClick={() => navigate('/search')}>
                        <Search sx={{ color: "white" }} />
                    </IconButton>

                    {/* Cart Icon with Badge */}
                    <IconButton onClick={() => navigate("/cart")}>
                        <Badge badgeContent={cartCount} color='error'>
                            <ShoppingCart sx={{ color: "white" }} />
                        </Badge>
                    </IconButton>

                    {/* User Dropdown */}
                    <div className='dropdown'>
                        <IconButton variant='outlined' className='dropdown-toggle' type='button' data-bs-toggle='dropdown' aria-expanded="false">
                            {
                               userDetails?.profile_picture ?<Avatar alt={userDetails?.name} src={validateImageUrl(userDetails?.profile_picture)}></Avatar> :  <Avatar>{userDetails?.name.slice(0,1)}</Avatar> 
                            }
                        </IconButton>
                        <ul className="dropdown-menu dropdown-menu-end">
                            <li><Link className="dropdown-item" to="/profile">Profile</Link></li>
                            <li><Link className="dropdown-item" to="/orders">Orders</Link></li>
                            <li><Link className="dropdown-item" to="/logout">Logout</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default NavBar;
