import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, Pagination, IconButton, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDeleteMealMutation, useFetchMealsQuery, useSearchMealQuery } from '../reducers/mealSlice';
import { Delete, Edit, VisibilitySharp, Search } from '@mui/icons-material';
import { toast } from 'react-toastify';
import NavBar from './components/NavBar';
import { useSelector } from 'react-redux';

const MealList = () => {
       const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    const page_size = 8;

    // ✅ Fetch paginated meals
    const { data: meals, isLoading, error, refetch } = useFetchMealsQuery({ page, page_size });

    // ✅ Fetch search results when searchTerm is present
    const { data: searchData } = useSearchMealQuery(searchTerm, { skip: !searchTerm });

    // ✅ Delete meal mutation
    const [deleteMeal] = useDeleteMealMutation();
    

    // ✅ Handle meal deletion
    const handleMealDelete = async (id) => {
        const confirm = window.confirm("Are you sure you want to delete this Meal?");
        if (confirm) {
            try {
                await deleteMeal(id);
                toast.success("Meal deleted successfully...");
                refetch(); // ✅ Refresh meal list after delete
            } catch (error) {
                toast.error("Error deleting meal. Please try again.");
            }
        }
    };

    // ✅ Decide which data to display: `searchData` if searching, else `meals`
    const displayedMeals = searchTerm ? searchData?.results : meals?.results;

    if (isLoading) return <CircularProgress />;
    if (error) return <p>Error loading meals...</p>;

    return (
        <div className='container-fluid'>
            <NavBar></NavBar>
        <div className="container" style={{marginTop:"100px"}}>
            <h2 className="text-center mb-4" style={{ marginTop: "70px" }}>Meal List</h2>

            {/* ✅ Search Bar */}
            <div className="d-flex justify-content-between mb-3">
                <TextField
                    variant="outlined"
                    placeholder="Search meals..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    InputProps={{
                        startAdornment: <Search />,
                    }}
                    style={{ width: "300px" }}
                />
            </div>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow style={{ backgroundColor: "#f5f5f5" }}>
                            <TableCell><strong>Picture</strong></TableCell>
                            <TableCell><strong>Name</strong></TableCell>
                            <TableCell><strong>Price</strong></TableCell>
                            <TableCell><strong>Category</strong></TableCell>
                            <TableCell><strong>Is Non-Veg?</strong></TableCell>
                            <TableCell><strong>Actions</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {displayedMeals?.length > 0 ? (
                            displayedMeals.map((meal) => (
                                <TableRow key={meal.id}>
                                    <TableCell><img src={meal.main_image} alt={meal.name} height={80} width={80} /></TableCell>
                                    <TableCell>{meal.name}</TableCell>
                                    <TableCell>RS {meal.price}</TableCell>
                                    <TableCell>{meal.category?.name || "N/A"}</TableCell>
                                    <TableCell>{meal.is_non_veg ? "Yes" : "No"}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => {}}>
                                            <VisibilitySharp />
                                        </IconButton>
                                        <IconButton onClick={() => navigate(`/route/admin/update-meal/${meal.id}`)}>
                                            <Edit />
                                        </IconButton>
                                        <IconButton color='error' onClick={() => handleMealDelete(meal.id)}>
                                            <Delete />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={6} className="text-center">
                                    No meals found.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* ✅ Show Pagination ONLY if not searching */}
            {!searchTerm && (
                <div className="d-flex justify-content-center mt-3">
                    <Pagination
                        count={Math.ceil(meals?.count / page_size) || 1}
                        page={page}
                        onChange={(event, value) => setPage(value)}
                        color="primary"
                    />
                </div>
            )}
        </div>
        </div>
    );
};

export default MealList;
