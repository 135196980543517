import React, { useEffect, useRef, useState } from 'react';
import { Button, TextField } from '@mui/material';
import { Formik, useFormik } from 'formik';
import { toast } from 'react-toastify';
import { useFetchAboutQuery, useCreateUpdateAboutMutation } from '../reducers/aboutSlice';
import { aboutSchema } from '../schemas/aboutSchema';
import NavBar from './components/NavBar';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { handleError } from '../helper/error';

const CreateAbout = () => {
    const bgImage = `${process.env.PUBLIC_URL}/images/hero-bg.png`;
    const addImage = `${process.env.PUBLIC_URL}/images/addimage.png`;
    
    const { data: aboutData, isLoading, error, refetch } = useFetchAboutQuery();
    const [createAbout, { isLoading: isUpdating }] = useCreateUpdateAboutMutation();
    const imageRef = useRef();   
    useEffect(() => {
        if (aboutData?.image) {
            setPreviewImage(aboutData.image);
        }
    }, [aboutData]);
    const [previewImage, setPreviewImage] = useState(addImage);
    console.log("about image: ",aboutData?.image)
    const formik = useFormik({
        initialValues: {
            description: aboutData?.description || '',
            contact_number: aboutData?.contact_number || '',
            email: aboutData?.email || '',
            image: null,
            opening_time: aboutData?.opening_time || '',
            closing_time: aboutData?.closing_time || '',
            facebook_url: aboutData?.facebook_url || '',
            instagram_url: aboutData?.instagram_url || '',
            tiktok_url: aboutData?.tiktok_url || '',
        },
        enableReinitialize: true,
        validationSchema: aboutSchema,
        onSubmit: async (values) => {
            const formData = new FormData();
            Object.keys(values).forEach((key) => {
                if (values[key]) {
                    formData.append(key, values[key] instanceof File ? values[key] : values[key]);
                }
            });

            try {
                const response = await createAbout(formData);
                if (response?.data) {
                    toast.success("Restaurant details updated successfully!");
                    refetch();
                }
            } catch (error) {
                toast.error(handleError(error))
            }
        },
    });
   console.log("error: ",formik.errors)
    const handleImageSelect = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => setPreviewImage(reader.result);
            reader.readAsDataURL(file);
            formik.setFieldValue("image", file);
        }
    };

    useEffect(() => {
        refetch();
    }, [refetch]);

    return (
        <div className='container-fluid' style={{ backgroundImage: `url(${bgImage})`, backgroundSize: "cover"}}>
            <NavBar></NavBar>
            <div className='container h-100' style={{marginTop:"100px"}}>
                <form onSubmit={formik.handleSubmit}>
                    <div className='row' style={{marginTop:"20px"}}>
                        <div className='col-md-8'>
                            <h2 className='text-white fs-3 text-center'>Update Restaurant Info</h2>
                            <TextField label="Description" fullWidth variant='filled' multiline rows={3} margin='normal' name='description' value={formik.values.description} onChange={formik.handleChange} onBlur={formik.handleBlur} error={formik.touched.description && Boolean(formik.errors.description)} helperText={formik.touched.description && formik.errors.description} sx={{ backgroundColor: 'white' }} />
                            <TextField label="Contact Number" fullWidth variant='filled' margin='normal' name='contact_number' value={formik.values.contact_number} onChange={formik.handleChange} onBlur={formik.handleBlur} error={formik.touched.contact_number && Boolean(formik.errors.contact_number)} helperText={formik.touched.contact_number && formik.errors.contact_number} sx={{ backgroundColor: 'white' }} />
                            <TextField label="Email" fullWidth variant='filled' margin='normal' name='email' value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur} error={formik.touched.email && Boolean(formik.errors.email)} helperText={formik.touched.email && formik.errors.email} sx={{ backgroundColor: 'white' }} />
                            <TextField label="Opening Time" type="time" fullWidth variant='filled' margin='normal' name='opening_time' value={formik.values.opening_time} onChange={formik.handleChange} sx={{ backgroundColor: 'white' }} />
                            <TextField label="Closing Time" type="time" fullWidth variant='filled' margin='normal' name='closing_time' value={formik.values.closing_time} onChange={formik.handleChange} sx={{ backgroundColor: 'white' }} />
                            <TextField label="Facebook URL" fullWidth variant='filled' margin='normal' name='facebook_url' value={formik.values.facebook_url} onChange={formik.handleChange} sx={{ backgroundColor: 'white' }} />
                            <TextField label="Instagram URL" fullWidth variant='filled' margin='normal' name='instagram_url' value={formik.values.instagram_url} onChange={formik.handleChange} sx={{ backgroundColor: 'white' }} />
                            <TextField label="TikTok URL" fullWidth variant='filled' margin='normal' name='tiktok_url' value={formik.values.tiktok_url} onChange={formik.handleChange} sx={{ backgroundColor: 'white' }} />
                        </div>
                        <div className='col-md-4 d-flex align-items-center flex-column'>
                            <label className="text-white mt-2">Restaurant Image</label>
                            <button type="button" className="btn btn-secondary mt-2" onClick={() => imageRef.current.click()}>
                                <img src={previewImage || addImage} style={{ height: "200px", width: "200px", objectFit: "fill" }} alt='Preview' />
                            </button>
                            <input type="file" name="image" className="d-none" ref={imageRef} onChange={handleImageSelect} />
                            <Button className="mt-3" variant="contained" disabled={isUpdating} sx={{ backgroundColor: "#ffbe33", color: "white" }} type="submit">
                                {isUpdating ? 'Updating...' : 'Save Changes'}
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreateAbout;