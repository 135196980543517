import React, { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import NavBar from "../components/NavBar";
import { useFetchAllMealQuery, useFetchMealCategoryQuery } from "../reducers/mealSlice";
import { useNavigate } from "react-router-dom";
import { useCreateCartMutation } from "../reducers/cartSlice";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ShoppingCart } from "@mui/icons-material";


const Menu = () => {
  const authData=useSelector((state)=>state.auth);
  const { data: allMeals } = useFetchAllMealQuery();
  const { data: mealCategories } = useFetchMealCategoryQuery();
  const [selectedCategory, setSelectedCategory] = useState("*");
  const gridRef = useRef(null);
  const isoRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (allMeals?.results?.length > 0) {
      isoRef.current = new Isotope(gridRef.current, {
        itemSelector: ".meal-item",
        layoutMode: "masonry",
        percentPosition: true,
      });
    }
  }, [allMeals]);

  const handleFilter = (categoryId) => {
    const filterValue = categoryId === "*" ? "*" : `.cat-${categoryId}`;
    setSelectedCategory(categoryId === "*" ? "*" : `cat-${categoryId}`);
    isoRef.current?.arrange({ filter: filterValue });
  };


   const [createCart, { isLoading, error }] = useCreateCartMutation();
    
    const addToCart = async (mealId) => {
      if(authData.is_admin){
        toast.warning("you can't perform this action as admin...")
        return;
      }
      if(!authData.is_authenticated){
        toast.warning("Login first...");
        return;
      }
      try {
        const response = await createCart({ meal: mealId }).unwrap(); // Call the API
        toast.success(response.message || "Meal added to cart!"); // Show success message
      } catch (err) {
        if (err?.data?.message) {
          toast.warning(err.data.message); // Show error message if provided
        } else {
          toast.error("Failed to add meal to cart. Please try again.");
        }
      }
    };

  return (
    <div className="container-fluid" style={{ padding: "0" }}>
      <NavBar />
      <section className="food_section layout_padding-bottom my-4">
        <div className="container">
          <div className="heading_container heading_center">
            <h2>Our Menu</h2>
          </div>

          {/* Category Filter */}
          <ul className="filters_menu">
            <li className={selectedCategory === "*" ? "active" : ""} onClick={() => handleFilter("*")}>
              All
            </li>
            {mealCategories?.results?.map((category) => (
              <li
                key={category.id}
                className={selectedCategory === `cat-${category.id}` ? "active" : ""}
                onClick={() => handleFilter(category.id)}
              >
                {category.name}
              </li>
            ))}
          </ul>

          {/* Meal Grid with Isotope */}
          <div className="filters-content">
            <div className="row grid" ref={gridRef}>
              {allMeals?.results?.map((meal) => (
                <div key={meal.id} className={`col-sm-6 col-lg-4 meal-item cat-${meal.category.id}`}>
                  <div className="box" style={{ cursor: "pointer" }}>
                    <div className="img-box">
                      <img src={meal?.main_image} alt={meal.name} onClick={() => navigate(`/meal/${meal.id}`)} />
                    </div>
                    <div className="detail-box">
                      <h5>{meal.name}</h5>
                      <p>{meal?.description?.slice(0, 120)}...</p>
                      <div className="options">
                        <h6>Rs {meal.price}</h6>
                          <button className="cart-button" 
                         onClick={() => addToCart(meal.id)}>
                         <ShoppingCart fontSize="small" />
                       </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Menu;
