import React, { useEffect } from 'react'
import Cookies from 'js-cookie';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setAdmin, setAuthenticated } from '../reducers/authSlice';
const axiosInstance=axios.create({
  baseURL:"https://api.pakizarestaurant.com",
  headers:{
    'Content-Type':"application/json",
  }
})
const AuthenticateUser = () => {
  const dispatch=useDispatch();
  useEffect(()=>{
  const authenticate=async()=>{
    try{
     const accessToken=Cookies.get("access");
     console.log("accessToken: ",accessToken)
     if(!accessToken){
      throw new Error("Access Token not found");
     }
     const response =await axiosInstance.post(
      `/account/validate-token/`,
       {access:accessToken},
       {headers:{'Content-Type':'application/json'}}
     );
     console.log("Authentication successful: ",response.data);
     dispatch(setAuthenticated(true));
     dispatch(setAdmin(response.data.is_admin));
    }catch(error){
      console.warn("Access Token failed: ",error)
       try{
        const refreshToken=Cookies.get('refresh');
        console.log("refreshToken: ",refreshToken)
        if(!refreshToken)throw new Error("Refresh token not found");
        const response=await axiosInstance.post(
          `/account/refresh-token/`,
          {refresh:refreshToken},
          {headers:{'Content-Type':'application/json'}}
        );
        const newAccessToken=response.data.access;
        Cookies.set('access',newAccessToken,{expires:1});
        dispatch(setAuthenticated(true));
        dispatch(setAdmin(response.data.is_admin));
        console.log("Token refreshed successfully..",newAccessToken)
       }catch(refreshError){
         console.error("Refresh token Failed: ",refreshError);
         dispatch(setAuthenticated(false));
         dispatch(setAdmin(false));
       }
    }
  }
  authenticate();
  },[]);
  return (
    <></>
  )
}

export default AuthenticateUser
