import * as Yup from 'yup';

export const adminUserSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .min(3, 'Full Name must be at least 3 characters')
    .max(50, 'Full Name cannot exceed 50 characters')
    .required('Full Name is required'),
  
  phone_number: Yup.string()
    .trim()
    .matches(/^(\+\d{1,3})?\d{10}$/, 'Enter a valid phone number')
    .required('Contact number is required'),
  
  password: Yup.string()
    .trim()
    .min(8, 'Password must be at least 8 characters')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/\d/, 'Password must contain at least one number')
    .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character')
    .required('Password is required'),
  
  confirm_password: Yup.string()
    .trim()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
});