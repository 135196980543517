import About from './pages/About';
import ForgotPassword from './pages/ForgetPassword';
import Profile from './pages/Profile';
import ChangePassword from './pages/ChangePassword';
import Logout from './pages/Logout';
import NotFound from './pages/NotFound';
import FeatureUnavailable from './pages/FeatureUnavailable';
import AddMeal from './admin/AddMeal';
import Meals from './admin/Meals';
import UpdateMeal from './admin/UpdateMeal';
import UserList from './admin/UserList';
import AddUserAdmin from './admin/AddAdmin';
import CreateAbout from './admin/About';
import MealList from './admin/MealList';
import Category from './admin/Category';
import Home from './pages/Home';
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Menu from './pages/Menu';
import Search from './pages/Search';
import Cart from './pages/Cart';
import Meal from './pages/Meal';
import Signup from './pages/Signup';
import Login from './pages/Login';
import Otp from './pages/Otp';
import { BrowserRouter as Router,Routes,Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const AppRoutes = () => {
    const isAuthenticated=useSelector((state)=>state.auth.is_authenticated)
    const isAdmin=useSelector((state)=>state.auth.is_admin);
  return (
    <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/menu" element={<Menu />} />
    <Route path="/search" element={<Search />} />
    <Route path="/cart" element={<Cart />} />
    <Route path="/meal/:id" element={<Meal />} />
    <Route path="/signup" element={<Signup />} />
    <Route path="/login" element={<Login />} />
    <Route path="/otp" element={<Otp />} />
    <Route path="/featureUnavailable" element={<FeatureUnavailable />} />
    <Route path="/about" element={<About />} />
    <Route path="/forget-password" element={<ForgotPassword />} />
    <Route path="/profile" element={<Profile />} />
    <Route path="/change-password" element={<ChangePassword />} />
    <Route path="/logout" element={<Logout />} />

    {/* ✅ Protect Admin Routes */}
    {/* {isAuthenticated && isAdmin ? (
      <>
        <Route path="/route/admin/add-meal" element={<AddMeal />} />
        <Route path="/route/admin/meals" element={<Meals />} />
        <Route path="/route/admin/update-meal/:id" element={<UpdateMeal />} />
        <Route path="/route/admin/meal-list" element={<MealList />} />
        <Route path="/route/admin/category" element={<Category />} />
        <Route path="/route/admin/user-list" element={<UserList />} />
        <Route path="/route/admin/about" element={<CreateAbout />} />
        <Route path="/route/admin/add-admin" element={<AddUserAdmin />} />
      </>
    ) : (
      <Route path="/route/admin/*" element={<Navigate to="/" />} />
    )} */}

{isAuthenticated && isAdmin && (
              <>
                <Route path="/route/admin/add-meal" element={<AddMeal />} />
                <Route path="/route/admin/meals" element={<Meals />} />
                <Route path="/route/admin/update-meal/:id" element={<UpdateMeal />} />
                <Route path="/route/admin/meal-list" element={<MealList />} />
                <Route path="/route/admin/category" element={<Category />} />
                <Route path="/route/admin/user-list" element={<UserList />} />
                <Route path="/route/admin/about" element={<CreateAbout />} />
                <Route path="/route/admin/add-admin" element={<AddUserAdmin />} />
              </>
            )}

    <Route path="*" element={<NotFound />} />
  </Routes>
  )
}

export default AppRoutes