import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Avatar, Badge, IconButton } from '@mui/material';
import { NotificationsNone } from '@mui/icons-material';
import { useUserProfileQuery } from '../../reducers/userSlice';
import { validateImageUrl } from '../../helper/validate';


const NavBar = () => {
    const navigate=useNavigate();
    const bgImage=`${process.env.PUBLIC_URL}/images/hero-bg.png`;
    const { data: userData, isLoading, error, refetch } = useUserProfileQuery();
    
  return (
    <nav className="navbar bg-dark fixed-top navbar-expand-lg bg-body-tertiary" data-bs-theme="dark" style={{backgroundImage:`url(${bgImage})`, backgroundSize: 'cover',
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "5px",
    paddingBottom: "5px"}}>
      <div className="container-fluid">
        {/*  Brand Name */}
       <Link className="navbar-brand" to="/">
                      <img src={`${process.env.PUBLIC_URL}/newlogo.png`}  alt='logo' height={80} width={210}style={{ objectFit: "contain",display: "block",maxWidth: "100%"  }}></img>
                  </Link>

        {/*  Navbar Toggler  */}
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" 
          data-bs-target="#navbarSupportedContent" 
          aria-controls="navbarSupportedContent" 
          aria-expanded="false" 
          aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        {/*  Navigation Links */}
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            
            <li className="nav-item active">
              <Link className="nav-link" aria-current="page" to="/">DASHBOARD</Link>
            </li>

            {/*  MEAL Dropdown */}
            <li className="nav-item dropdown">
              <Link className="nav-link dropdown-toggle" to="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                MEAL
              </Link>
              <ul className="dropdown-menu">
                <li><Link className='dropdown-item' to="/route/admin/add-meal">Add Meal</Link></li>
                <li><Link className="dropdown-item" to="/route/admin/category">Category</Link></li>
                {/* <li><a className="dropdown-item" href="/">Meal List</a></li> */}
                <li><Link className='dropdown-item' to='/route/admin/meal-list'>Meal List</Link></li>
              </ul>
            </li>

            {/*  ORDER Dropdown */}
            <li className="nav-item dropdown">
              <Link className="nav-link dropdown-toggle" to="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                ORDER
              </Link>
              <ul className="dropdown-menu">
                <li><Link className="dropdown-item" to="/route">Order List</Link></li>
              </ul>
            </li>

            {/*  USERS Dropdown */}
            <li className="nav-item dropdown">
              <Link className="nav-link dropdown-toggle" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                USERS
              </Link>
              <ul className="dropdown-menu">
                <li><Link className="dropdown-item" to="/route/admin/user-list">All User</Link></li>
                <li><Link className="dropdown-item" to="/route/admin/add-admin">Add Admin</Link></li>
              </ul>
              
            </li>

            <li className="nav-item">
              <Link className="nav-link" aria-current="page" to="/route/admin/about">About</Link>
            </li>
          </ul>
        </div>

        {/* ✅ Avatar Dropdown Positioned at Right */}
        <div className="ms-auto d-flex align-items-center">

        <IconButton onClick={()=>navigate("/cart")}>
              <Badge badgeContent={4} color='error'>
                <NotificationsNone sx={{color:"white"}}></NotificationsNone>
              </Badge>
             </IconButton>

          <div className='dropdown'>
            <IconButton variant='outlined' className='dropdown-toggle' type='button' 
              data-bs-toggle='dropdown' aria-expanded="false">
               {
                               userData?.profile_picture ?<Avatar alt={userData?.name} src={validateImageUrl(userData?.profile_picture)}></Avatar> :  <Avatar>{userData?.name.slice(0,1)}</Avatar> 
                            }
            </IconButton>
            <ul className="dropdown-menu dropdown-menu-end">
              <li><Link className="dropdown-item" to="/profile">Profile</Link></li>
              <li><Link className="dropdown-item" to="#">Settings</Link></li>
              <li><Link className="dropdown-item" to="/logout">Logout</Link></li>
            </ul>
          </div>
        </div>

      </div>
    </nav>
  );
};

export default NavBar;
