import React, { useEffect, useState } from "react";
import NavBar from "../components/NavBar";
import { InputAdornment, TextField } from "@mui/material";
import { useSearchMealQuery } from "../reducers/mealSlice";
import { validateImageUrl } from "../helper/validate";
import Footer from "../components/Footer";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useCreateCartMutation } from "../reducers/cartSlice";
import { incrementCartCount } from "../reducers/cartCountSlice";
import { ShoppingCart } from "@mui/icons-material";

const Search = () => {
  const authData=useSelector((state)=>state.auth);
  const [searchText, setSearchText] = useState("");
  const [skip, setSkip] = useState(true);
  const { data: meals, isLoading, error } = useSearchMealQuery(searchText, { skip });
  const dispatch=useDispatch();
  useEffect(() => {
    if (searchText.trim() !== "") {
      setSkip(false);
    }
   }, [searchText]);

   const [createCart, { isLoading:createCartLoding, error:createCartError }] = useCreateCartMutation();
   const addToCart = async (mealId) => {
    if(authData.is_admin){
      toast.warning("you can't perform this action as admin...")
      return;
    }
    if(!authData.is_authenticated){
            toast.warning("Login first...");
            return;
    }

    try {
      const response = await createCart({ meal: mealId }).unwrap(); // Call the API
      dispatch(incrementCartCount());
      toast.success(response.message || "Meal added to cart!"); // Show success message

    } catch (err) {
      if (err?.data?.message) {
        toast.warning(err.data.message); // Show error message if provided
      } else {
        toast.error("Failed to add meal to cart. Please try again.");
      }
    }
  };
  






  return (
    <div
      className="container-fluid"
      style={{ padding: "0", minHeight: "100vh", backgroundSize: "cover" }}
    >
      <NavBar />
      <div className="container">
        <div className="col-md-4 col-12">
          <TextField
            variant="filled"
            label="Search Meal"
            sx={{
              backgroundColor: "#fff3e0",
              margin: "10px",
              "& .MuiOutlinedInput-root": {
                "&:hover fieldset": {
                  borderColor: "#e65100",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#e65100",
                },
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "#e65100",
              },
            }}
            fullWidth
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <i className="bi bi-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
      <div className="container">
        <section className="food_section layout_padding">
          <div className="filters-content">
            <div className="row grid">
              {isLoading ? (
                <p>Loading meals...</p>
              ) : error ? (
                <p>Error loading meals</p>
              ) : meals?.results?.length > 0 ? (
                meals.results.map((meal) => (
                  <div key={meal.id} className="col-sm-6 col-lg-4 all">
                    <div className="box">
                      <div>
                        <div className="img-box">
                          <img src={validateImageUrl(meal.main_image)} alt={meal.name} loading="lazy" />
                        </div>
                        <div className="detail-box">
                          <h5>{meal.name}</h5>
                          <p>{meal.description.slice(0, 120)}....</p>
                          <div className="options">
                            <h6>Rs {meal.price}</h6>
                            <button className="cart-button" 
  onClick={() => addToCart(meal.id)}>
  <ShoppingCart fontSize="small" />
</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-center mt-4" style={{ fontSize: "18px" }}>
                  No meals found <span role="img" aria-label="sad">😢</span>
                </p>
              )}
            </div>
          </div>
        </section>
        <Footer></Footer>
      </div>
    </div>
  );
};

export default Search;
