import React from 'react'
import { useFetchAboutQuery } from '../reducers/aboutSlice'
import { Link } from 'react-router-dom';

const Footer = () => {
    const{data:aboutData}=useFetchAboutQuery();
  return (
    <footer className="footer_section">
    <div className="container">
      <div className="row">
        <div className="col-md-4 footer-col">
          <div className="footer_contact">
            <h4>
              Contact Us
            </h4>
            <div className="contact_link_box">
              <Link to="">
                <i className="bi bi-telephone-fill" aria-hidden="true"></i>
                <span>
                   { " "+ aboutData?.contact_number.toString()}
                </span>
              </Link>
              <br></br>
              <Link to="">
                <i className="bi bi-envelope-fill" aria-hidden="true"></i>
                <span>
                    {" "+ aboutData?.email}
                </span>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-md-4 footer-col">
        <h4>Follow Us</h4>
          <div className="footer_detail">
            <div className="footer_social">
              {aboutData?.facebook_url &&  <Link to={aboutData?.facebook_url} target="_blank">
                <i className="bi bi-facebook" aria-hidden="true"></i>
              </Link>}
              {aboutData?.instagram_url && <Link to={aboutData?.instagram_url} target="_blank">
                <i className="bi bi-instagram" aria-hidden="true"></i>
              </Link>}
              {
                aboutData?.tiktok_url&& <Link to={aboutData?.tiktok_url} target="_blank">
                <i className="bi bi-tiktok" aria-hidden="true"></i>
              </Link>
              }
            </div>
          </div>
        </div>
        <div className="col-md-4 footer-col">
          <h4>
            Opening Hours
          </h4>
          <p>
            Everyday
          </p>
          <p>
  {aboutData?.opening_time && aboutData?.closing_time &&
    `${parseInt(aboutData.opening_time.slice(0, 2), 10) % 12 || 12}:${aboutData.opening_time.slice(3, 5)} ${parseInt(aboutData.opening_time.slice(0, 2), 10) >= 12 ? 'PM' : 'AM'} - 
     ${parseInt(aboutData.closing_time.slice(0, 2), 10) % 12 || 12}:${aboutData.closing_time.slice(3, 5)} ${parseInt(aboutData.closing_time.slice(0, 2), 10) >= 12 ? 'PM' : 'AM'}`}
</p>

        </div>
      </div>
      <div className="footer-info">
        <p>
          &copy; <span id="displayYear"></span> All Rights Reserved By
          <Link to ="">  Pakiza Restaurant</Link><br/><br/>
          &copy; <span id="displayYear"></span> Developed By
          <Link href="" target="_blank">  arunchy600@gmail.com</Link>
        </p>
      </div>
    </div>
  </footer>
  )
}

export default Footer