import { authApi } from "../authentication/auth";

export const userApiSlice=authApi.injectEndpoints({
    endpoints:(builder)=>{
        return{
            fetchUsers:builder.query({
                query:({page,page_size})=>({
                    url:`/account/users/?page=${page}&page_size=${page_size}`,
                    method:"GET"
                })
            }),
            deleteUser:builder.mutation({
                query:(id)=>({
                    url:`/account/${id}/delete/`,
                    method:"DELETE"
                })
            }),
            searchUser:builder.query({
                query:(search_term)=>({
                  url:`/account/search/?q=${search_term}`,
                  method:'GET'
                })
            }),
            createUserAdmin:builder.mutation({
                query:(userData)=>({
                    url:"/account/users/",
                    method:"POST",
                    body:userData
                })
            }),
            resendOtp:builder.mutation({
                query:(resendData)=>({
                    url:'/account/resend-otp/',
                    method:'POST',
                    body:resendData
                })
            }),
            userProfile:builder.query({
                query:()=>({
                    url:'/account/user-profile/',
                    method:"GET"
                })
            }),
            updateUserProfile:builder.mutation({
                query:(profileData)=>({
                    url:'/account/user-profile/',
                    method:"PUT",
                    body:profileData
                })
            }),
            changePassword:builder.mutation({
                query:(changePasswordData)=>({
                   url:'/account/change-password/',
                   method:"POST",
                   body:changePasswordData

                })
            })
        }
    }
});


export const {useFetchUsersQuery,useDeleteUserMutation,useSearchUserQuery,useCreateUserAdminMutation,useResendOtpMutation,useUserProfileQuery,useUpdateUserProfileMutation,useChangePasswordMutation}=userApiSlice;