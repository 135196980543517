import { Button, TextField, List, ListItem, ListItemText, CircularProgress, IconButton } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useCreateMealCategoryMutation, useDeleteMealCategoryMutation, useFetchMealCategoryQuery, useUpdateMealCategoryMutation,  } from '../reducers/mealSlice';

import { toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import NavBar from './components/NavBar';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
const Category = () => {
    const bgImage = `${process.env.PUBLIC_URL}/images/hero-bg.png`;
    const closeBtnRef=useRef();
    // ✅ RTK Query hooks
    const [createMealCategory, { isLoading }] = useCreateMealCategoryMutation();
    const [deleteMealCategory] = useDeleteMealCategoryMutation();
    const [updateMealCategory] = useUpdateMealCategoryMutation();
    const { data: categoryData, isLoading: loadingCategory, error: categoryError, refetch } = useFetchMealCategoryQuery();

    // ✅ Local States
    const [categoryName, setCategoryName] = useState('');
    const [editCategoryId, setEditCategoryId] = useState(null);
    const [editCategoryName, setEditCategoryName] = useState('');

    // ✅ Add Category
    const handleAddCategory = async () => {
        if (!categoryName.trim()) {
            toast.error("Category name can't be empty");
            return;
        }

        try {
            const response = await createMealCategory({ name: categoryName });
            if (response?.data) {
                toast.success("Category added successfully!");
                setCategoryName('');
                refetch();
            } else {
                toast.error("Failed to add category");
            }
        } catch (err) {
            toast.error("An error occurred while adding category");
        }
    };

    // ✅ Delete Category
    const handleDeleteMealCategory = async (id) => {
        toast.warning("All the meals of this category will also be deleted...", { autoClose: 3000 });
        setTimeout(async () => {
            const confirm = window.confirm("Are you sure you want to delete this Meal Category?");
            if (confirm) {
                try {
                    await deleteMealCategory(id);
                    toast.success("Meal Category deleted successfully...");
                    refetch();
                } catch (error) {
                    toast.error("Error deleting meal category. Please try again.");
                }
            }
        },1500); 
    };

   

    // ✅ Update Category
    const handleUpdateCategory = async () => {
        if (!editCategoryName.trim()) {
            toast.error("Category name can't be empty");
            return;
        }
        const formData=new FormData();
        formData.append("name",editCategoryName);

        try {
            await updateMealCategory({ id: editCategoryId, name: formData });
            toast.success("Category updated successfully");
            refetch();
            if(closeBtnRef.current){
             closeBtnRef.current.click();
            }

        } catch (error) {
            toast.error("Error updating category");
        }
    };

    const handleOpenUpdateModal=async(id)=>{
      console.log("category id",id);
    // ✅ Find the category by id in categoryData.results
    const category = categoryData?.results?.find((category) => category.id === id);

    if (category) {
        setEditCategoryName(category.name); // ✅ Set the category name
        setEditCategoryId(id);
    } else {
        console.error("Category not found!");
    }
    }

    return (
        <div className='container-fluid' style={{ backgroundImage: `url(${bgImage})`, backgroundSize: "cover", minHeight: "100vh" }}>
         <div className='container h-100'>
         <NavBar></NavBar>
                <div className='row' style={{ marginTop: "100px",}}>

                    {/* ✅ Left Side - Add Category */}
                    <div className='col-sm-6 my-3'>
                        <h2 className='text-white text-center'>Add Category</h2>
                        <TextField
                            label="Category Name"
                            variant='filled'
                            margin='normal'
                            name='name'
                            type='text'
                            value={categoryName}
                            onChange={(e) => setCategoryName(e.target.value)}
                            sx={{ backgroundColor: 'white' }}
                            fullWidth
                        />
                        <Button
                            className='mt-2'
                            variant='contained'
                            sx={{ backgroundColor: "#ffbe33", color: "white" }}
                            onClick={handleAddCategory}
                            disabled={isLoading}
                        >
                            {isLoading ? 'Adding...' : 'Add Category'}
                        </Button>
                    </div>

                    {/* ✅ Right Side - List of Categories */}
                    <div className='col-sm-6 my-3'>
                        <h2 className='text-white text-center'>Meal Categories</h2>
                        {loadingCategory ? (
                            <div className="d-flex justify-content-center">
                                <CircularProgress color="secondary" />
                            </div>
                        ) : categoryError ? (
                            <p className='text-danger'>Failed to load categories</p>
                        ) : (
                            <List sx={{ backgroundColor: 'white', borderRadius: '8px', maxHeight: '400px', overflowY: 'auto', marginTop: "20px" }}>
                                {categoryData?.results?.length > 0 ? (
                                    categoryData?.results?.map((category) => (
                                        <ListItem
                                            key={category.id}
                                            secondaryAction={
                                                <>
                                                    <IconButton edge="end" aria-label="edit" color='primary' data-bs-toggle="modal" data-bs-target="#editCategoryModal" onClick={()=>handleOpenUpdateModal(category.id)}>
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton edge="end" aria-label="delete" color='error' onClick={() => handleDeleteMealCategory(category.id)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </>
                                            }
                                        >
                                            <ListItemText primary={<>{category.name} <span className='badge bg-warning'>{category.meal_count}</span></>} />
                                        </ListItem>
                                    ))
                                ) : (
                                    <ListItem>
                                        <ListItemText primary="No categories found." />
                                    </ListItem>
                                )}
                            </List>
                        )}
                    </div>
                </div>
            </div>

            {/* ✅ Bootstrap Modal for Edit */}
            <div className="modal fade" id="editCategoryModal" tabIndex="-1" aria-labelledby="editCategoryModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="editCategoryModalLabel">Edit Category</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ref={closeBtnRef}></button>
                        </div>
                        <div className="modal-body">
                            <TextField
                                label="Category Name"
                                variant='outlined'
                                fullWidth
                                value={editCategoryName}
                                onChange={(e) => setEditCategoryName(e.target.value)}
                            />
                        </div>
                        <div className="modal-footer">
                            <Button variant='contained' sx={{ backgroundColor: "#ffbe33", color: "white" }} onClick={handleUpdateCategory}>Update</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Category;
