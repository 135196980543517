import { authApi } from "../authentication/auth";


export const cartApi=authApi.injectEndpoints({
    endpoints:(builder)=>{
        return{
            fetchUserCart:builder.query({
                query:()=>({
               url:"/cart/cart/",
               method:"GET"
                })
            }),
            createCart:builder.mutation({
                query:(cartData)=>({
                 url:"/cart/cart/",
                 method:"POST",
                 body:cartData
                })
            }),
            deleteUserCart:builder.mutation({
                query:(meal_id)=>({
                    url:`/cart/delete/${meal_id}/`,
                    method:"DELETE",

                })
            })
        }
    }
});


export const{useCreateCartMutation,useFetchUserCartQuery,useDeleteUserCartMutation}=cartApi;