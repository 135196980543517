import React, { useEffect, useRef, useState } from 'react';
import { Button, Checkbox, TextField, FormControlLabel, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';
import { useFetchMealCategoryQuery, useFetchMealByIdQuery, useUpdateMealMutation } from '../reducers/mealSlice';
import { useParams, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { updateMealSchema } from '../schemas/updateMealSchema';
import NavBar from './components/NavBar';
import { useSelector } from 'react-redux';

const UpdateMeal = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const bgImage = `${process.env.PUBLIC_URL}/images/hero-bg.png`;
    const addImage = `${process.env.PUBLIC_URL}/images/addimage.png`;

    // ✅ Fetch Meal Data
    const { data: mealData, isLoading, error, refetch } = useFetchMealByIdQuery(id);
    const [updateMeal, { isLoading: isUpdating }] = useUpdateMealMutation();
    const { data: categoryData, isLoading: categoryLoading, error: categoryError, refetch: fetchCategory } = useFetchMealCategoryQuery();

    const mainImageRef = useRef();
    const image1Ref = useRef();
    const image2Ref = useRef();

    // ✅ Image Preview State
    const [previewImage, setPreviewImage] = useState({
        main_image: mealData?.main_image,
        image1: mealData?.image1,
        image2: mealData?.image2,
    });

    useEffect(() => {
        refetch();
        fetchCategory();
    }, [refetch, fetchCategory]);

    // ✅ Handle Image Selection
    const handleImageSelect = (e) => {
        const { name } = e.target;
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewImage((prev) => ({
                    ...prev,
                    [name]: reader.result,
                }));
            };
            reader.readAsDataURL(file);
            formik.setFieldValue(name, file);
        }
    };

    // ✅ Formik Setup
    const formik = useFormik({
        initialValues: {
            name: mealData?.name || '',
            price: mealData?.price || '',
            half_plate_price: mealData?.half_plate_price || '',
            meal_type: mealData?.meal_type || 'Plate',
            ingredients: mealData?.ingredients || '',
            description: mealData?.description || '',
            main_image: null,
            image1: null,
            image2: null,
            category_id: mealData?.category?.id || '',
            is_non_veg: mealData?.is_non_veg || false,
        },
        enableReinitialize: true, // ✅ Allows reinitialization when mealData is fetched
        validationSchema: updateMealSchema,
        onSubmit: async (values) => {
            // ✅ Remove half_plate_price if meal_type is "Piece"
            if (values.meal_type === "Piece") {
                delete values.half_plate_price;
            }
        
            // ✅ Convert values to FormData
            const formData = new FormData();
            Object.keys(values).forEach((key) => {
                if (values[key]) {
                    if (typeof values[key] === "boolean") {
                        formData.append(key, values[key] ? "true" : "false");
                    } else if (values[key] instanceof File) {
                        formData.append(key, values[key]);
                    } else {
                        formData.append(key, values[key]);
                    }
                }
            });
        
            try {
                // ✅ Send Update API Request
                const response = await updateMeal({ id, updateData: formData });
        
                if (response?.data) {
                    toast.success("Meal updated successfully!");
                    navigate("/route/admin/meal-list"); // Redirect after success
                } else {
                    toast.error(`Failed to update meal: ${response.error?.data?.message || "Something went wrong!"}`);
                }
            } catch (error) {
                toast.error("An unexpected error occurred. Please try again.");
            }
        },
        
    });

    console.log("errors: ", formik.errors);

    if (isLoading) return <p>Loading meal details...</p>;
    if (error) return <p>Error fetching meal: {error.message}</p>;

    return (
        <div className='container-fluid ' style={{ backgroundImage: `url(${bgImage})`, backgroundSize: "cover",minHeight: "100vh"}}>
            <NavBar></NavBar>
            <div className='container' style={{marginTop: "100px", }}>
                <div className='row my-2'>
                    <div className='col-sm-8'>
                        <form onSubmit={formik.handleSubmit}>
                            <p className='text-white fs-3 text-center' style={{ marginBottom: "0" }}>Update Meal</p>

                            {/* ✅ Meal Name */}
                            <TextField label="Meal Name" variant='filled' fullWidth margin='normal' name='name'
                                value={formik.values.name} onChange={formik.handleChange} onBlur={formik.handleBlur}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name} sx={{ backgroundColor: 'white' }}
                            />

                            {/* ✅ Meal Type */}
                            <FormControl fullWidth sx={{ marginBottom: "20px", backgroundColor: "white" }} variant='filled'>
                                <InputLabel>Meal Type</InputLabel>
                                <Select name="meal_type" value={formik.values.meal_type} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                    <MenuItem value="Plate">Plate</MenuItem>
                                    <MenuItem value="Piece">Piece</MenuItem>
                                </Select>
                            </FormControl>

                            {/* ✅ Meal Price */}
                            <TextField label="Meal Price" fullWidth variant='filled' margin='normal' name='price'
                                value={formik.values.price} onChange={formik.handleChange} onBlur={formik.handleBlur}
                                error={formik.touched.price && Boolean(formik.errors.price)}
                                helperText={formik.touched.price && formik.errors.price} sx={{ backgroundColor: 'white' }}
                            />

                            {/* ✅ Half Plate Price (only if Plate) */}
                            <TextField label="Half Plate Price" fullWidth variant='filled' margin='normal' name='half_plate_price'
                                value={formik.values.half_plate_price} onChange={formik.handleChange} onBlur={formik.handleBlur}
                                disabled={formik.values.meal_type === "Piece"}
                                error={formik.touched.half_plate_price && Boolean(formik.errors.half_plate_price)}
                                helperText={formik.touched.half_plate_price && formik.errors.half_plate_price} sx={{ backgroundColor: 'white' }}
                            />

                            {/* ✅ Meal Category */}
                            <FormControl fullWidth sx={{ marginBottom: "20px", backgroundColor: "white" }} variant='filled'>
                                <InputLabel>Meal Category</InputLabel>
                                <Select name="category_id" value={formik.values.category_id} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                    {categoryData?.results?.map((category) => (
                                        <MenuItem value={category.id} key={category.id}>{category.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            {/* ✅ Meal Ingredients */}
                            <TextField label="Meal Ingredients" variant='filled' fullWidth multiline rows={2} name='ingredients'
                                value={formik.values.ingredients} onChange={formik.handleChange} onBlur={formik.handleBlur}
                                sx={{ backgroundColor: 'white', marginBottom: "20px" }}
                            />

                            {/* ✅ Meal Description */}
                            <TextField label="Meal Description" variant='filled' fullWidth multiline rows={2} name='description'
                                value={formik.values.description} onChange={formik.handleChange} onBlur={formik.handleBlur}
                                sx={{ backgroundColor: 'white' }}
                            />

                            {/* ✅ Is Non Veg Checkbox */}
                            <FormControlLabel control={<Checkbox name="is_non_veg" checked={formik.values.is_non_veg} onChange={formik.handleChange} />} label="Is Non Veg" sx={{ color: "white" }} />

                            {/* ✅ Submit Button */}
                            <br></br>

                             <Button className='mt-2' variant='contained' disabled={isUpdating} sx={{ backgroundColor: "#ffbe33", color: "white" }} type='submit'>
                                                                                                {isUpdating ? 'Updating..' : 'Update Meal'}
                                                                                            </Button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpdateMeal;
