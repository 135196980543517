import {createApi,fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import { setAdmin, setAuthenticated } from "../reducers/authSlice";
import store from "../store/store";
import Cookies from "js-cookie";
const verifyUserAuthorization=async(args,api,extraOptions)=>{
    const baseQuery=fetchBaseQuery({
        baseUrl:"https://api.pakizarestaurant.com",
        prepareHeaders:(headers)=>{
           const token=Cookies.get("access");
           console.log("token: ",token)
            headers.set('Authorization',`Bearer ${token}`);
            return headers;
        }
        
    });
    const result=await baseQuery(args,api,extraOptions);
    if(result.error && result.error.status===401){
        store.dispatch(setAuthenticated(false))
        
    }
    return result;

}

export const authApi=createApi({
    baseQuery:verifyUserAuthorization,
    endpoints:(builder)=>({
        userLogin:builder.mutation({
            query:(loginData)=>({
                url:'/account/login/',
                method:'POST',
                body:loginData,
            }),
async onQueryStarted(arg,{dispatch,queryFulfilled}){
    console.log("login query started....")
    try{
        const {data}=await queryFulfilled;
        console.log("logindata: ",data)
        const accessToken=data.access_token;
        const refreshToken=data.refresh_token;
        document.cookie=`access=${accessToken}; path=/; max-age=18000;samesite=lax;secure=false;`
        document.cookie=`refresh=${refreshToken}; path=/;max-age=18000;samesite=lax;,secure=false;`
        dispatch(setAuthenticated(true));
        if(data.is_admin){
            dispatch(setAdmin(data.is_admin))
        }
    }catch(error) {
    //handle error 
      dispatch(setAuthenticated(false));
      dispatch(setAdmin(false));
    }
}
        }),
        userOtp:builder.mutation({
         query:(signupData)=>({
          url:'/account/otp_validation/',
          method:'POST',
          body:signupData
         }),
        }),
        verifyUser:builder.mutation({
            query:()=>({
                url:'/account/verify/',
                method:'POST',
                
            })
        }),
        userSignup:builder.mutation({
            query:(userData)=>({
                url:'/account/signup/',
                method:'POST',
                body:userData,
            }),
async onQueryStarted(arg,{dispatch,queryFulfilled}){
    console.log("login query started....")
    try{
        const {data}=await queryFulfilled;
        console.log('login data: ',data);
        dispatch(setAuthenticated(true));
        if(data.is_admin){
            dispatch(setAdmin(true))
        }
    }catch(error) {
    //handle error 
      dispatch(setAuthenticated(false));
      dispatch(setAdmin(false));
    }
}
        }),
       
    
    })
})

export const {useUserLoginMutation,useUserOtpMutation,useUserSignupMutation}=authApi;


